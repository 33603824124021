import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import './OpportunityContactSidebar.scss';
import { OpportunityDetailsType } from '@ts/opportunity.types';
import { SVGIcon } from '@uikit/Icon/Icon';
import OpportunityContactSidebarSectionCampaigns from '@components/CampaignSettings/OpportunityContactSidebar/_components/OpportunityContactSidebarSectionCampaigns/OpportunityContactSidebarSectionCampaigns';
import OpportunityContactSidebarSectionTags from '@components/CampaignSettings/OpportunityContactSidebar/_components/OpportunityContactSidebarSectionTags/OpportunityContactSidebarSectionTags';
import OpportunityContactSidebarSectionNotes from '@components/CampaignSettings/OpportunityContactSidebar/_components/OpportunityContactSidebarSectionNotes/OpportunityContactSidebarSectionNotes';
import OpportunityContactSidebarSectionMetrics from '@components/CampaignSettings/OpportunityContactSidebar/_components/OpportunityContactSidebarSectionMetrics/OpportunityContactSidebarSectionMetrics';
import OpportunityContactSidebarSectionContacts from '@components/CampaignSettings/OpportunityContactSidebar/_components/OpportunityContactSidebarSectionContacts/OpportunityContactSidebarSectionContacts';
import OpportunityContactSidebarSectionHistory from '@components/CampaignSettings/OpportunityContactSidebar/_components/OpportunityContactSidebarSectionHistory/OpportunityContactSidebarSectionHistory';
import ContactsSidebarCRMSectionEditableField from '@components/ContactSidebarCRM/_components/ContactsSidebarCRMSection/_components/ContactsSidebarCRMSectionEditableField/ContactsSidebarCRMSectionEditableField';
import { getDateShort } from '@utils/date';
import OpportunityContactSidebarSectionCustomVariables from '@components/CampaignSettings/OpportunityContactSidebar/_components/OpportunityContactSidebarSectionCustomVariables/OpportunityContactSidebarSectionCustomVariables';
import ContactsSidebarCRMSectionOpportunities from '@components/ContactSidebarCRM/_components/ContactsSidebarCRMSectionOpportunities/ContactsSidebarCRMSectionOpportunities';
import { useQuery, useQueryClient } from 'react-query';
import { getOpportunityByIdApi } from '@api/campaign.api';
import { useSelector } from 'react-redux';
import { getActiveCampaignInfo } from '@redux/selectors/campaignSettings.selectors';
import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';
import Loader from '@uikit/Loader/Loader';

declare const IS_DEV: boolean;

type Props = {
  opportunityId: number;
  refetchActiveOpportunity?: () => void;
};

const SECTION_TYPE = {
  CONTACTS: 'contacts',
  TAGS: 'tags',
  METRICS: 'metrics',
  CAMPAIGNS: 'campaigns',
  NOTES: 'notes',
  HISTORY: 'history',
  VARIABLES: 'variables',
};

// TODO add check on IS_STANDALONE_MODE
if (true) {
  // @ts-ignore
  SECTION_TYPE.OPPORTUNITIES = 'opportunities';
}

const sectionsMap = {
  [SECTION_TYPE.TAGS]: OpportunityContactSidebarSectionTags,
  [SECTION_TYPE.CAMPAIGNS]: OpportunityContactSidebarSectionCampaigns,
  [SECTION_TYPE.NOTES]: OpportunityContactSidebarSectionNotes,
  [SECTION_TYPE.CONTACTS]: OpportunityContactSidebarSectionContacts,
  [SECTION_TYPE.METRICS]: OpportunityContactSidebarSectionMetrics,
  [SECTION_TYPE.HISTORY]: OpportunityContactSidebarSectionHistory,
  [SECTION_TYPE.VARIABLES]: OpportunityContactSidebarSectionCustomVariables,
};

// TODO add check on IS_STANDALONE_MODE
if (true) {
  // @ts-ignore
  sectionsMap[SECTION_TYPE.OPPORTUNITIES] = ContactsSidebarCRMSectionOpportunities;
}

function OpportunityContactSidebarContent({ opportunityId, refetchActiveOpportunity }: Props) {
  const [sections, setSections] = useState([]);
  const queryClient = useQueryClient();
  const QUERY_KEY_BASE = 'active-opportunity';
  const { info: campaignInfo } = useSelector(getActiveCampaignInfo);
  const workspaceId = useCurrentWorkspaceId();

  const { data: activeOpportunity, isLoading: isLoadingActiveOpportunity } = useQuery(
    [QUERY_KEY_BASE, opportunityId],
    () => getOpportunityByIdApi(opportunityId, campaignInfo.workspaceId, campaignInfo.id),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!campaignInfo && !!opportunityId,
    }
  );

  const handleDragDrop = (res) => {
    const { source, destination } = res;

    if (!destination || destination?.index === source?.index) {
      return;
    }

    setSections((state) => {
      const newState = state.slice();
      newState.splice(source.index, 1);
      newState.splice(destination.index, 0, state[source.index]);

      localStorage.setItem('opportunityContactsSectionsOrder', JSON.stringify(newState));
      return newState;
    });
  };

  useEffect(() => {
    const sectionOrder = localStorage.getItem('opportunityContactsSectionsOrder');

    try {
      const sectionOrderParsed = JSON.parse(sectionOrder);

      if (sectionOrderParsed && sectionOrderParsed.length === Object.values(SECTION_TYPE).length) {
        setSections(sectionOrderParsed);
      } else {
        localStorage.removeItem('opportunityContactsSectionsOrder');
        setSections(Object.values(SECTION_TYPE));
      }
    } catch {
      localStorage.removeItem('opportunityContactsSectionsOrder');
      setSections(Object.values(SECTION_TYPE));
    }
  }, []);

  return (
    <div className="opportunity-contacts-sidebar-crm__content">
      <DragDropContext onDragEnd={handleDragDrop}>
        <Droppable droppableId="opportunity-contacts-sidebar" direction="vertical">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps} className="">
              {sections.map((key, index) => {
                const Section = sectionsMap[key];
                // eslint-disable-next-line react/no-array-index-key
                return (
                  <Section
                    opportunity={activeOpportunity}
                    index={index}
                    key={index}
                    actions={{ refetchActiveOpportunity }}
                  />
                );
              })}

              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

function OpportunityContactSidebar({
  opportunityId,
  refetchActiveOpportunity,
}: Props): JSX.Element {
  const QUERY_KEY_BASE = 'active-opportunity';
  const { info: campaignInfo } = useSelector(getActiveCampaignInfo);

  const { data: activeOpportunity, isLoading: isLoadingActiveOpportunity } = useQuery(
    [QUERY_KEY_BASE, opportunityId],
    () => getOpportunityByIdApi(opportunityId, campaignInfo.workspaceId, campaignInfo.id),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!campaignInfo && !!opportunityId,
    }
  );

  if (isLoadingActiveOpportunity || activeOpportunity == null) {
    return <Loader isLoading />;
  }

  const { website, url } = activeOpportunity;
  let domain = website?.website;

  if (!domain) {
    const isValidHttpUrl = (string) => {
      let parsedUrl;

      try {
        parsedUrl = new URL(string);
      } catch (_) {
        return false;
      }

      return parsedUrl.protocol === 'https:';
    };

    domain = isValidHttpUrl(url) ? new URL(url).hostname : url;
  }

  const handleSaveCompany = () => {};

  return (
    <div className="opportunity-contacts-sidebar-crm--small">
      <div className="opportunity-contacts-sidebar-crm-header">
        <div className="opportunity-contacts-sidebar-crm-header-column">
          <div className="opportunity-contacts-sidebar-crm-header-name">
            {website.title || 'Url'}
          </div>
          <div
            className="opportunity-contacts-sidebar-crm-header-company"
            onClick={() => window.open(`https://${domain}`, '_blank')}
          >
            {domain}
            <SVGIcon icon="externalLink" size={12} color="#221cb6" />
          </div>
        </div>
      </div>
      {website.id > 0 ? (
        <div className="opportunity-contacts-sidebar-crm__content-personal-info">
          <div className="opportunity-contacts-sidebar-crm__content-personal-info-label">
            Website name:
          </div>
          <ContactsSidebarCRMSectionEditableField
            initialValue={website.title}
            onSave={handleSaveCompany}
            renderContent={(value) => (value == null || value.length === 0 ? '-' : value)}
          />

          <div className="opportunity-contacts-sidebar-crm__content-personal-info-label">
            Created on:{' '}
          </div>
          <div className="opportunity-contacts-sidebar-crm__content-personal-info-data-row">
            {getDateShort(new Date(website.createdAt), true, true)}
          </div>
        </div>
      ) : null}
      {/*  <div className="opportunity-contacts-sidebar-crm-header"> */}
      {/*    <div className="opportunity-contacts-sidebar-crm-header-column"> */}
      {/*      <div className="opportunity-contacts-sidebar-crm-header-name"> */}
      {/*        {person?.name || '-'} */}
      {/*      </div> */}
      {/*    </div> */}
      {/*  </div> */}
      <OpportunityContactSidebarContent
        opportunityId={opportunityId}
        refetchActiveOpportunity={refetchActiveOpportunity}
      />
    </div>
  );
}

export default OpportunityContactSidebar;
