import RelationshipStatus from '@components/Relationships/PeopleTable/_components/RelationshipStatus';
import EmailAccuracy from '@uikit/EmailAccuracy/EmailAccuracy';
import SocialLinks from '@uikit/SocialLinks/SocialLinks';
import React, { useEffect, useState } from 'react';

import { SVGIcon } from '@uikit/Icon/Icon';

import './OpportunityContactSidebarSectionContacts.scss';
import { getOpportunitiesStatsApi, removeOpportunityContactApi } from '@api/campaign.api';
import { useDispatch, useSelector } from 'react-redux';
import { OpportunityDetailsType, OpportunitySidebarProps } from '@ts/opportunity.types';
import OpportunityContactSidebarSection from '@components/CampaignSettings/OpportunityContactSidebar/_components/OpportunityContactSidebarSection/OpportunityContactSidebarSection';
import { addNotification } from '@redux/actions/notifications.actions';
import { useQuery, useQueryClient } from 'react-query';
import { OpportunityContactContainerType } from '@ts/contact.types';
import { DispatchType } from 'src/store';
import { getActiveCampaignInfo } from '@redux/selectors/campaignSettings.selectors';
import ManualConfirmContactSideBar from '@components/CampaignSettings/ManualConfirmContactSideBar/ManualConfirmContactSideBar';
import SubscriptionActivationSidebar from '@components/SubscriptionActivationSidebars/SubscriptionActivationSidebar';
import { fetchBillingCredits, fetchBillingDetails } from '@redux/thunks/billings.thunks';
import UpsellCreditsSidebar from '@components/UpsellSidebars/UpsellCreditsSidebar/UpsellCreditsSidebar';
import { ContactSearchSource as CONTACT_SEARCH_SOURCE } from 'respona_api/generated/common_pb';
import {
  currentContactAutomationStateSelector,
  currentContactAutomationTypeSelector,
} from '@redux/selectors/automationContact.selectors';
import { ContactAutomationSearchModes } from '@ts/automationContacts.types';

function OpportunityContactSidebarSectionContacts({
  opportunity,
  index,
  actions,
}: OpportunitySidebarProps) {
  const { contactsList: contacts, website } = opportunity;
  const dispatch = useDispatch<DispatchType>();
  const queryClient = useQueryClient();
  const {
    info: { id: campaignId, workspaceId },
  } = useSelector(getActiveCampaignInfo);
  const [openContactSidebar, setOpenContactSidebar] = useState<boolean>(false);
  const [isOpen, setOpen] = useState(contacts.length > 0);
  const [isPaymentSidebarOpen, setPaymentSidebarOpen] = useState<boolean>(false);
  const [upsellCreditsSidebarOpened, setUpsellCreditsSidebarOpened] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(contacts.length > 0);
  const [upsellCreditsNotEnough, setUpsellCreditsNotEnough] = useState<number>(0);

  useEffect(() => {
    if ((isOpen && contacts == null) || contacts.length <= 0) {
      setOpen(false);
      setIsExpanded(false);
    }
  }, [contacts]);

  const removeContact = (event, contactId) => {
    event.stopPropagation();
    removeOpportunityContactApi(opportunity.id, workspaceId, contactId).then(
      (res: OpportunityContactContainerType) => {
        queryClient.setQueryData(
          ['active-opportunity', opportunity.id],
          (cachedOpp: OpportunityDetailsType) => ({
            ...cachedOpp,
            contactsList: cachedOpp.contactsList.filter((it) => it.contactId !== contactId),
          })
        );
        queryClient.setQueryData(
          ['opportunities-with-metrics', campaignId, workspaceId, []],
          ({ pages, pageParams }) => {
            const newPages = pages[0].map((item) => {
              if (item.id !== opportunity.id) {
                return item;
              }

              return {
                ...item,
                contactsList: item.contactsList.filter((it) => it.contactId !== contactId),
              };
            });

            return {
              pageParams,
              pages: [newPages],
            };
          }
        );
        dispatch(addNotification({ title: 'Contact removed', type: 'success' }));
      }
    );
  };

  // const handleCreatePerson = (personDetails) => {
  //   return createPersonApi(personDetails).then((personResponse) => {
  //     addContactToOpportunityApi(
  //       workspaceId,
  //       opportunity.id,
  //       personResponse.id,
  //       personResponse.emailsList[0].email,
  //       5,
  //       campaignInfo.id,
  //       personResponse.emailsList[0].accuracy,
  //       lastBatch
  //     )
  //       .then((response) => {
  //         queryClient.setQueryData(
  //           ['active-opportunity', opportunity.id],
  //           (cachedOpp: OpportunityDetailsType) => {
  //             const newOppo = {
  //               ...cachedOpp,
  //               contactsList: [...cachedOpp.contactsList, response],
  //             };
  //             return newOppo;
  //           }
  //         );
  //         dispatch(addNotification({ title: 'Contact successfully added', type: 'success' }));
  //         queryClient.setQueryData(
  //           ['opportunities-with-metrics', campaignInfo.id, campaignInfo.workspaceId],
  //           ({ pageParams, pages }) => {
  //             const updatedPages = pages.map((page: OpportunityPageType[]) => {
  //               page.map((opp: OpportunityPageType) => {
  //                 if (opp.id === opportunity.id) {
  //                   opp.contactsList.push(response);
  //                 }
  //                 return opp;
  //               });
  //               return page;
  //             });
  //             return { pageParams, pages: updatedPages };
  //           }
  //         );
  //       })
  //       .catch((error) => {
  //         if (error.message.indexOf('Contact already exist with email') !== -1) {
  //           dispatch(
  //             addNotification({
  //               title:
  //                 'You have already selected this contact in one of opportunities in this campaign',
  //               type: 'error',
  //             })
  //           );
  //         }
  //         return null;
  //       });
  //   });
  // };

  const automationContact = useSelector(currentContactAutomationStateSelector);
  const automationType = useSelector(currentContactAutomationTypeSelector);
  const { info: campaignInfo } = useSelector(getActiveCampaignInfo);

  const { data: dataOpportunitiesStats, isLoading: isLoadingOpportunitiesStats } = useQuery(
    [
      'opportunities-stats-counter',
      campaignInfo.id,
      campaignInfo.workspaceId,
      campaignInfo.lastBatch,
    ],
    () =>
      getOpportunitiesStatsApi(campaignInfo.id, campaignInfo.workspaceId, campaignInfo.lastBatch),
    {
      enabled: !!campaignInfo.id,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (isLoadingOpportunitiesStats === false) {
      setUpsellCreditsNotEnough(getCardsInfo().secondValue);
    }
  }, [isLoadingOpportunitiesStats]);

  const unassignedOpportunitiesCount = () => {
    if (isLoadingOpportunitiesStats) {
      return 0;
    }
    return dataOpportunitiesStats.withoutContacts;
  };

  const getCardsInfo = () => {
    const opportunitiesWithoutContactsNumber = unassignedOpportunitiesCount();
    if (opportunitiesWithoutContactsNumber == null) {
      return {
        secondValue: 0,
      };
    }
    const assignNumber =
      automationType === ContactAutomationSearchModes.BLOG_POST ||
      automationType === ContactAutomationSearchModes.DOMAINS
        ? automationContact.assignNumber || 2
        : 1;

    const contactsPerOpportunityNumber = opportunitiesWithoutContactsNumber * assignNumber;

    return {
      firstTitle: 'Unassigned opportunities',
      firstValue: opportunitiesWithoutContactsNumber,
      secondTitle:
        automationContact.source !== CONTACT_SEARCH_SOURCE.RESPONA_SEARCH_SOURCE
          ? 'Estimated searches'
          : 'Reserved credits',
      secondValue:
        automationType === ContactAutomationSearchModes.EXPERIMENTAL
          ? opportunitiesWithoutContactsNumber + contactsPerOpportunityNumber * 50
          : automationContact.source !== CONTACT_SEARCH_SOURCE.RESPONA_SEARCH_SOURCE
            ? opportunitiesWithoutContactsNumber + contactsPerOpportunityNumber
            : opportunitiesWithoutContactsNumber + contactsPerOpportunityNumber * 5,
      thirdTitle: 'Time (mins)',
      thirdValue: Math.ceil(contactsPerOpportunityNumber * 1.1 + Math.random() * 0.2),
    };
  };

  return (
    <OpportunityContactSidebarSection
      index={index}
      id="opportunity-contacts"
      title="Contacts"
      showArrow={contacts?.length > 0}
      onOpen={() => setOpen(!isOpen)}
      isInitialOpen={isOpen}
      rightComponent={
        <div
          className="opportunity-contacts-sidebar-crm__content__add-btn"
          onClick={() => setOpenContactSidebar(true)}
        >
          <SVGIcon icon="plusIcon" size={10} color="#221CB6" />
          Add
        </div>
      }
    >
      {isPaymentSidebarOpen && (
        <SubscriptionActivationSidebar
          title="Activate your subscription"
          onClose={() => {
            setPaymentSidebarOpen(false);
          }}
          onActionAfterPay={() => {
            setPaymentSidebarOpen(false);
            dispatch(fetchBillingDetails());
            dispatch(fetchBillingCredits());
          }}
        />
      )}

      {openContactSidebar && (
        <ManualConfirmContactSideBar
          activeOpportunity={opportunity as any}
          onClose={() => {
            setOpenContactSidebar(false);
          }}
          setPaymentSidebarOpen={setPaymentSidebarOpen}
          setUpsellCreditsSidebarOpened={setUpsellCreditsSidebarOpened}
          refetchActiveOpportunity={actions.refetchActiveOpportunity}
        />
      )}

      {upsellCreditsSidebarOpened && (
        <UpsellCreditsSidebar
          title="You don&rsquo;t have enough credits for this action. How would you like to proceed?"
          onClose={() => setUpsellCreditsSidebarOpened(false)}
          needForAutomation={upsellCreditsNotEnough}
        />
      )}

      {contacts?.length > 0 ? (
        <div className="opportunity-contacts-sidebar-section-contacts__contacts">
          {contacts?.slice(0, isExpanded ? undefined : 3)?.map((contact) => (
            <div
              className="opportunity-contacts-sidebar-section-contacts__contacts-contact"
              key={contact.contactId}
            >
              <div
                className="opportunity-contacts-sidebar-section-contacts__contacts-contact-title"
                onClick={() =>
                  window.open(`/relationships/people?id=${contact.personId}`, '_blank')
                }
              >
                <span>{contact.name}</span>
                <div className="opportunity-contacts-sidebar-section-contacts__contacts-contact-title-social">
                  <SocialLinks socialLinks={contact.socialLinksList} />
                </div>

                <span
                  className="opportunity-contacts-sidebar-section-contacts__contacts-contact-title-close"
                  onClick={(event) => removeContact(event, contact.contactId)}
                >
                  <SVGIcon icon="crossInGreyCircle" />
                </span>
              </div>

              <div className="opportunity-contacts-sidebar-section-contacts__contacts-contact-data">
                <SVGIcon icon="envelope" size={16} color="#C4C6CD" />
                <span className="opportunity-contacts-sidebar-section-contacts__contacts-contact-data-email">
                  {contact.contactEmail.email}
                  <EmailAccuracy accuracy={contact.contactEmail.accuracy} />
                </span>
              </div>
              {contact.jobPosition ? (
                <div className="opportunity-contacts-sidebar-section-contacts__contacts-contact-data">
                  <SVGIcon icon="personRounded" size={15} color="#C4C6CD" />
                  <span>{contact.jobPosition}</span>
                </div>
              ) : null}
              <div className="opportunity-contacts-sidebar-section-contacts__contacts-contact-data">
                <SVGIcon icon="flagStriped" size={16} color="#C4C6CD" />
                <RelationshipStatus status={contact.status} isBlocked={false} withIcon={false} />
              </div>
            </div>
          ))}

          {!isExpanded && contacts?.length > 3 && (
            <div
              className="opportunity-contacts-sidebar-crm__show-more-btn"
              onClick={() => setIsExpanded(true)}
            >
              Show More
              <SVGIcon icon="chevron" size={8} />
            </div>
          )}
        </div>
      ) : (
        <div className="opportunity-contacts-sidebar-section-tags--empty">Empty</div>
      )}
    </OpportunityContactSidebarSection>
  );
}

export default OpportunityContactSidebarSectionContacts;
