import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import copy from 'copy-to-clipboard';
import cn from 'classnames';

import { DispatchType } from 'src/store';

import { addNotification } from '@redux/actions/notifications.actions';

import { SVGIcon, SvgIconType } from '@uikit/Icon/Icon';
import Input from '@uikit/Input/Input';

import './ContactsSidebarCRMSectionEditableField.scss';

const ContactsSidebarCRMSectionEditableField = ({
  initialValue,
  onSave,
  onDelete,
  onRefresh,
  onClose,
  isOpen = false,
  isRefreshing,
  enableCopy = true,
  enableLink = false,
  placeholder = 'Enter new value',
  renderContent,
  isRenderIcon = false,
  renderIcon,
  extraClass = '',
  extraRowClass = '',
}: {
  initialValue: string;
  onSave: (initialValue: string, email: string) => void;
  onRefresh?: () => void;
  onDelete?: (email: string) => void;
  onClose?: () => void;
  isOpen?: boolean;
  isRefreshing?: boolean;
  enableCopy?: boolean;
  enableLink?: boolean;
  placeholder?: string;
  renderContent?: (string: string) => string;
  isRenderIcon?: boolean;
  renderIcon?: SvgIconType;
  extraClass?: string;
  extraRowClass?: string;
}) => {
  const dispatch = useDispatch<DispatchType>();
  const editableRowRef = useRef<HTMLDivElement>();

  const [isEditing, setIsEditing] = useState<boolean>(isOpen);
  const [value, setValue] = useState<string>(initialValue || '');

  const handleCopyClick = (text: string) => {
    copy(text);
    dispatch(addNotification({ title: 'Copied to clipboard', type: 'info', delay: 1000 }));
  };

  const resetState = () => {
    setIsEditing(false);
    setValue(initialValue);
  };

  const handleInputKeyDown = (e) => {
    if (e.keyCode === 27 || (e.keyCode === 13 && !value)) {
      resetState();
      onClose && onClose();
    } else if (e.keyCode === 13 && !!value) {
      onSave(initialValue, value);
      setIsEditing(false);
      onClose && onClose();
    }
  };

  const handleBlur = () => {
    if (!!value && value !== initialValue) {
      onSave(initialValue, value);
    }

    setIsEditing(false);
    onClose && onClose();
  };

  useEffect(() => {
    if (value !== initialValue) {
      setValue(initialValue);
    }
  }, [initialValue]);

  return (
    <div
      ref={editableRowRef}
      className={cn(`contacts-sidebar-crm-section__editable-row ${extraRowClass}`, {
        'contacts-sidebar-crm-section__editable-row--is-editing': isEditing,
      })}
    >
      {isEditing ? (
        <Input
          onBlur={handleBlur}
          onKeyDown={handleInputKeyDown}
          value={value}
          onChange={({ target }) => setValue(target.value)}
          placeholder={placeholder}
        />
      ) : (
        <span className={`render-content ${extraClass}`}>
          {isRenderIcon && <SVGIcon icon={renderIcon} size={16} />}
          <span className="render-content-text">{renderContent ? renderContent(value) : value}</span>
        </span>
      )}

          {!isEditing && (
            <div className="contacts-sidebar-crm-section__editable-btn-row">
              {!!onRefresh && (
                <div
                  className={cn('contacts-sidebar-crm-section__editable-btn', {
                    'contacts-sidebar-crm-section__editable-btn--refreshing': isRefreshing,
                  })}
                  onClick={() => onRefresh()}
                >
                  <SVGIcon icon="refresh" size={11} color="#221CB6" />
                </div>
              )}

              {enableLink && (
                <div
                  className="contacts-sidebar-crm-section__editable-btn"
                  onClick={() => window.open(initialValue, '_blank')}
                >
                  <SVGIcon icon="externalLink" size={11} color="#221CB6" />
                </div>
              )}

              {enableCopy && (
                <div
                  className="contacts-sidebar-crm-section__editable-btn"
                  onClick={() => handleCopyClick(initialValue)}
                >
                  <SVGIcon icon="copy" size={11} color="#8F9199" />
                </div>
              )}

              <div
                className="contacts-sidebar-crm-section__editable-btn"
                onClick={() => setIsEditing(true)}
              >
                <SVGIcon icon="pencil" size={11} color="#221CB6" />
              </div>

              {!!onDelete && (
                <div
                  className="contacts-sidebar-crm-section__editable-btn"
                  onClick={() => onDelete(initialValue)}
                >
                  <SVGIcon icon="trashSimple" size={11} color="#ED483D" />
                </div>
              )}
            </div>
          )}
    </div>
      );
      };

      export default ContactsSidebarCRMSectionEditableField;
