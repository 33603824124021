import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { components } from 'react-select';
import { useQuery } from 'react-query';
import { debounce } from 'lodash';

import { Button } from '@uikit/Button/Button';
import Select from '@uikit/Select/Select';
import Avatar from '@uikit/Avatar/Avatar';
import RightSidebar from '@components/RightSidebar/RightSidebar';
import CustomVariable from '@components/SidebarCRM/sections/SidebarCRMSectionVariables/_components/CustomVariable/CustomVariable';

import useWorkspaceMembers from '@hooks/useWorkspaceMembers';
import useRelationshipPipelines from '@hooks/useRelationshipPipelines';
import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';
import useRelationshipPipelineStages from '@hooks/useRelationshipPipelineStages';
import useCustomVariables from '@hooks/useCustomVariables';
import useRelationshipOpportunitySuggestions from '@hooks/useRelationshipOpportunitySuggestions';

import { WebsiteType } from '@ts/company.types';

import { CustomVariableValueRelatedTo } from 'respona_api/generated/common_pb';
import { createStageOpportunityApi } from '@api/relationshipPipeline';
import { getWebsiteByIdApi } from '@api/website.api';

import './DealsSidebar.scss';
import {
  CustomVariablePlaceholderType,
  CustomVariableSelectItemValueType,
  CustomVariableSelectType,
  CustomVariableValueType,
} from '@ts/customVariales.types';
import { reactSelectStylesProvider } from '@helpers/reactSelectHelpers';
import { GREY_COLOR } from '@constants/colors';
import Input from '@uikit/Input/Input';
import { PipelineOpportunityType } from '@ts/relationshipPipeline.types';

type Props = {
  isOpen: boolean;
  isUpdate?: boolean;
  onClose: () => void;
  onSave?: (
    data: PipelineOpportunityType,
    additionalData: { pipelineId: number; pipelineTitle: string }
  ) => void;
  extraClass?: string;
  itemId?: number;
  stageId?: number;
  isDisabledDomain?: boolean;
  isDisabledWebsiteName?: boolean;
};

type WebsiteOptions = {
  value: number;
  label: string;
  domain?: string;
  logo?: string;
};

const DealsSidebar: React.FC<Props> = ({
  isOpen,
  isUpdate,
  onClose,
  onSave,
  extraClass,
  itemId = -1,
  stageId,
  isDisabledDomain = false,
  isDisabledWebsiteName = false,
}) => {
  const location = useLocation();
  const [isSaveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(true);
  const [itemVariableValues, changeItemVariableValues] = useState<CustomVariableValueType[]>([]);
  const [selectedPipeline, setSelectedPipeline] = useState(null);
  const [selectedStage, setSelectedStage] = useState(null);

  const { members } = useWorkspaceMembers();
  const sortedMembers = [
    {
      value: -1,
      label: 'Unassigned',
      logoUrl: '',
    },
    ...members
      .sort((a, b) => Number(b.isMe) - Number(a.isMe))
      .map((member) => ({
        value: member.userId,
        label: member.isMe ? 'Assign to me' : member.fullName,
        logoUrl: member.logoUrl,
      })),
  ];

  const [selectedAssignee, setSelectedAssignee] = useState(sortedMembers[0]);
  const [searchWebsiteTitle, setSearchWebsiteTitle] = useState('');
  const currentWorkspaceId = useCurrentWorkspaceId();
  const [website, setWebsite] = useState<WebsiteOptions>({
    value: itemId,
    label: 'Select website',
  });

  const [websiteName, setWebsiteName] = useState('');
  const { items: pipelines } = useRelationshipPipelines(currentWorkspaceId);
  const { items: stages } = useRelationshipPipelineStages(
    selectedPipeline?.value,
    currentWorkspaceId
  );

  const onComponentClose = () => {
    setWebsite({
      value: -1,
      label: 'Select website',
    });
    setSelectedAssignee(sortedMembers[0]);
    setSelectedPipeline(null);
    setSelectedStage(null);
    changeItemVariableValues([]);
    onClose();
  };

  const { items: recordsAndSuggestions, refetch: refetchSuggestions } =
    useRelationshipOpportunitySuggestions(currentWorkspaceId, searchWebsiteTitle);

  const debouncedCompanyTitleChange = useCallback(
    debounce((value) => {
      if (value.length >= 2) {
        refetchSuggestions();
      }
    }, 350),
    [refetchSuggestions]
  );

  const handleCompanyTitleChange = useCallback(
    (value) => {
      setSearchWebsiteTitle(value);
      debouncedCompanyTitleChange(value);
    },
    [debouncedCompanyTitleChange]
  );

  const pipelineId = useMemo(() => {
    const query = new URLSearchParams(location.search);
    return Number.parseInt(query.get('id'), 10) || 0;
  }, [location.search]);

  useEffect(() => {
    if (pipelineId > 0 && pipelines?.length > 0) {
      const activePipeline = pipelines.find((it) => it.id === pipelineId);
      if (activePipeline) {
        setSelectedPipeline({ label: activePipeline.title, value: activePipeline.id });
      }
    }
  }, [pipelines, pipelineId]);

  useEffect(() => {
    if (stages.length === 0) {
      return;
    }
    if (stageId === -1) {
      setSelectedStage(null);
    }
    const activeStage = stages.find((stage) => stage.id === stageId);
    if (activeStage) {
      setSelectedStage({ label: activeStage.title, value: activeStage.id });
    }
  }, [stageId]);

  useEffect(() => {
    if (
      isSaveButtonDisabled &&
      selectedPipeline?.value > 0 &&
      selectedStage?.value > 0 &&
      (website?.value > 0 || website?.domain?.length > 0)
    ) {
      setSaveButtonDisabled(false);
    }
  }, [selectedPipeline, selectedStage, website]);

  const { data: existingWebsite } = useQuery<WebsiteType>(
    ['websites-sidebar-website', itemId, currentWorkspaceId],
    () => getWebsiteByIdApi(itemId, currentWorkspaceId),
    {
      enabled: itemId > 0,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (existingWebsite != null) {
      setWebsite({
        value: existingWebsite.id,
        domain: existingWebsite.domain,
        label: existingWebsite.title,
        logo: existingWebsite.logoUrl,
      });
      setWebsiteName(existingWebsite.title);
    }
  }, [existingWebsite]);

  const handleChangeWebsite = (option: WebsiteOptions) => {
    setWebsite(option);
    setWebsiteName(option.label);
  };

  const suggestionOptions = () => {
    const records = [];
    const suggestions = [];

    recordsAndSuggestions?.forEach((item) => {
      const mappedItem = {
        label: item.title,
        value: item.id,
        logo: item.logo,
        domain: item.domain,
      };
      if (item.id === -1) {
        suggestions.push(mappedItem);
      } else {
        records.push(mappedItem);
      }
    });

    return [
      {
        label: 'Records',
        options: records,
      },
      {
        label: 'Suggestions',
        options: suggestions,
      },
    ];
  };

  const { customVariables } = useCustomVariables(
    CustomVariableValueRelatedTo.RELATIONSHIP_OPPORTUNITY
  );
  const customVariablesByType = useMemo(
    () =>
      customVariables?.filter(
        (it) => it.relatedTo === CustomVariableValueRelatedTo.RELATIONSHIP_OPPORTUNITY
      ) || [],
    [customVariables]
  );

  const handleSave = () => {
    createStageOpportunityApi(
      selectedStage?.value,
      currentWorkspaceId,
      website.value,
      website.logo,
      website.domain,
      websiteName || website.label,
      selectedAssignee.value,
      itemVariableValues
    ).then((response) => {
      setWebsite({
        value: -1,
        domain: null,
        label: 'Select website',
        logo: null,
      });
      setWebsiteName('');
      onSave &&
        onSave(response, {
          pipelineId: selectedPipeline.value,
          pipelineTitle: selectedPipeline.label,
        });
      onComponentClose();
    });
  };

  const pipelineOptions = useMemo(() => {
    return pipelines?.map((pip) => {
      return { label: pip.title, value: pip.id };
    });
  }, [pipelines]);

  const stageOptions = useMemo(() => {
    return stages?.map((pip) => {
      return { label: pip.title, value: pip.id };
    });
  }, [stages]);

  function WebsiteOption(props) {
    const { label, logo, domain } = props.data;
    return (
      <components.Option {...props}>
        <div className="pipeline-deal-card__website-info">
          <Avatar
            url={logo}
            size="sm"
            fullName={label}
            wrapperClassName="pipeline-deal-card__website-info-logo"
          />
          <div className="pipeline-deal-card__website-info-container">
            <div>{label}</div>
            <div className="pipeline-deal-card__website">{domain}</div>
          </div>
        </div>
      </components.Option>
    );
  }

  const handleSaveStringVariable = useCallback(
    (newInput: string, variable: CustomVariablePlaceholderType, value: CustomVariableValueType) => {
      const newVariable = value
        ? { ...value, stringValue: newInput }
        : ({
            variableId: variable.id,
            variableName: variable.name,
            type: variable.type,
            relatedTo: variable.relatedTo,
            relatedId: -1,
            stringValue: newInput,
          } as CustomVariableValueType);
      changeItemVariableValues((prevState) => [...prevState, newVariable]);
    },
    []
  );

  const handleSaveSelectVariable = useCallback(
    (
      newInput: { label: string; value: string }[],
      variable: CustomVariablePlaceholderType,
      value: CustomVariableValueType
    ) => {
      const select = {
        valuesList: newInput.map((it) => {
          return { uid: it.value, value: it.label } as CustomVariableSelectItemValueType;
        }),
      } as CustomVariableSelectType;
      const newVariable = value
        ? ({ ...value, selectValue: select } as CustomVariableValueType)
        : ({
            variableId: variable.id,
            variableName: variable.name,
            type: variable.type,
            relatedTo: variable.relatedTo,
            relatedId: -1,
            stringValue: null,
            selectValue: select,
          } as CustomVariableValueType);
      changeItemVariableValues((prevState) => [...prevState, newVariable]);
    },
    []
  );

  const selectFieldAdditionalStyle = {
    control: reactSelectStylesProvider({
      borderColor: GREY_COLOR,
      borderRadius: '6px',
      height: '45px',
      padding: '2px 11px',
    }),
  };

  return (
    <div className={`custom-fields-sidebar ${extraClass}`}>
      <RightSidebar
        isOpen={isOpen}
        onClose={onComponentClose}
        showFooter={false}
        title={
          <div className="custom-fields-sidebar__header">
            <span>
              {isUpdate ? 'Update' : 'Create'} a {isUpdate ? '' : 'new '}deal
            </span>
          </div>
        }
      >
        <ul className="custom-fields-sidebar__list">
          <li className="custom-fields-sidebar__list-item">
            <div className="custom-fields-sidebar__list-item-title">Website domain</div>
            <Select
              value={{ ...website, label: website.domain }}
              options={suggestionOptions()}
              onChange={(option) => {
                // if clicked "create"
                if (option.__isNew__) {
                  handleChangeWebsite({
                    label: option.label,
                    value: -1,
                    logo: '',
                    domain: option.value,
                  });
                } else {
                  handleChangeWebsite(option);
                }
              }}
              onInputChange={handleCompanyTitleChange}
              placeholder="Website domain"
              additionalStyles={selectFieldAdditionalStyle}
              additionalComponents={{ Option: WebsiteOption }}
              noOptionsMessage={() => 'Type website domain'}
              isCreatableSelect
              isDisabled={isDisabledDomain}
            />
          </li>
          <li className="custom-fields-sidebar__list-item">
            <div className="custom-fields-sidebar__list-item-title">Website name</div>
            <Input
              className="website-name"
              value={websiteName}
              onChange={(event) => setWebsiteName(event.target.value)}
              placeholder="Type website name"
              disabled={isDisabledWebsiteName}
            />
          </li>
          <li className="custom-fields-sidebar__list-item">
            <div className="custom-fields-sidebar__list-item-title">Pipeline</div>
            <Select
              value={selectedPipeline}
              options={pipelineOptions}
              onChange={(option) => setSelectedPipeline(option)}
              additionalStyles={selectFieldAdditionalStyle}
              placeholder="Pipeline"
            />
          </li>
          <li className="custom-fields-sidebar__list-item">
            <div className="custom-fields-sidebar__list-item-title">Stage</div>
            <Select
              value={selectedStage}
              options={stageOptions}
              onChange={(option) => setSelectedStage(option)}
              isSearchable={false}
              additionalStyles={selectFieldAdditionalStyle}
              placeholder="Stage"
            />
          </li>
          <li className="custom-fields-sidebar__list-item">
            <div className="custom-fields-sidebar__list-item-title">Assignee</div>
            <Select
              value={selectedAssignee}
              options={sortedMembers}
              onChange={(option) => setSelectedAssignee(option)}
              isSearchable={false}
              additionalStyles={selectFieldAdditionalStyle}
              placeholder="Assignee"
            />
          </li>

          {customVariablesByType.map((customVariableByType) => (
            <li key={customVariableByType.id} className="custom-fields-sidebar__list-item">
              <CustomVariable
                item={customVariableByType}
                variableValues={itemVariableValues}
                members={members}
                handleSaveStringVariable={handleSaveStringVariable}
                handleSaveSelectVariable={handleSaveSelectVariable}
              />
            </li>
          ))}
        </ul>

        <div className="custom-fields-sidebar__footer">
          <Button type="primary" disabled={isSaveButtonDisabled} onClick={handleSave}>
            Save
          </Button>
          <Button type="additional" onClick={onComponentClose}>
            Cancel
          </Button>
        </div>
      </RightSidebar>
    </div>
  );
};

export default DealsSidebar;
