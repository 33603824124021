import React, { useState } from 'react';

import Checkbox from '@uikit/Checkbox/Checkbox';
import { SVGIcon } from '@uikit/Icon/Icon';
import Input from '@uikit/Input/Input';
import Loader from '@uikit/Loader/Loader';

import './TooltipSearchableContent.scss';
import { addNotification } from '@redux/actions/notifications.actions';
import { DispatchType } from 'src/store';
import { useDispatch } from 'react-redux';

type ItemType = { title: string; selected: boolean; id: number };

type Props = {
  title?: React.ReactNode;
  items?: ItemType[];
  addButtonText?: React.ReactNode;
  onCreate?: (title: string) => void;
  customCreateAction?: () => void;
  onSelect?: (item: ItemType, selected: boolean) => void;
  isLoading?: boolean;
  children?: any;
  searchDisabled?: boolean;
};

const TooltipSearchableContent = ({
  children,
  items,
  onCreate,
  onSelect,
  customCreateAction,
  addButtonText,
  isLoading,
  title,
  searchDisabled = false,
}: Props) => {
  const [search, setSearch] = useState<string>('');
  const [newItem, setNewItem] = useState<string>('');
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const dispatch: DispatchType = useDispatch();

  const handleCheck = (payload) => {
    if (payload?.length > 0) {
      const itemExists = items.some((element) => element.title === payload);

      if (itemExists) {
        dispatch(
          addNotification({
            title: `This item named ${payload} already exists`,
            type: 'error',
          })
        );
      } else {
        const createNewItem = async (item) => {
          await onCreate(item);
          setIsCreating(false);
          setNewItem('');
        };

        createNewItem(payload);
      }
    }
  };

  return (
    <div className="tooltip-searchable-content">
      <div className="tooltip-searchable-content-header">{title}</div>

      {!searchDisabled && (
        <div className="tooltip-searchable-content-search">
          <Input
            icon="search"
            iconColor="#747474"
            value={search}
            onChange={({ target: { value } }) => setSearch(value)}
            placeholder="Search"
          />
        </div>
      )}

      <Loader withTopMargin isLoading={isLoading}>
        {children}

        {items?.length > 0 ? (
          <div className="tooltip-searchable-content-items">
            <div className="tooltip-searchable-content-items-label">All tags</div>

            {items
              .slice()
              .filter((item) => item.title.toUpperCase().includes(search.toUpperCase()))
              .map((item) => (
                <div key={item.id} className="tooltip-searchable-content-items-row">
                  <span>{item.title}</span>

                  <Checkbox
                    onChange={({ target: { checked } }) => onSelect(item, checked)}
                    value={item.selected || false}
                  />
                </div>
              ))}
          </div>
        ) : null}

        {!!items && items?.length === 0 ? (
          <div className="tooltip-searchable-content-items--empty">Empty</div>
        ) : null}
      </Loader>

      <div className="tooltip-searchable-content-add-btn">
        {isCreating ? (
          <div className="tooltip-searchable-content-add-btn-input">
            <Input
              value={newItem}
              onChange={({ target: { value } }) => setNewItem(value)}
              placeholder="Enter title"
            />
            <span onClick={() => handleCheck(newItem)}>
              <SVGIcon icon="checkCircled" />
            </span>
            <span
              onClick={() => {
                setNewItem('');
                setIsCreating(false);
              }}
            >
              <SVGIcon icon="crossDelete" size={18} />
            </span>
          </div>
        ) : (
          <span onClick={() => (customCreateAction ? customCreateAction() : setIsCreating(true))}>
            {addButtonText}
          </span>
        )}
      </div>
    </div>
  );
};

export default TooltipSearchableContent;
