import React, { useCallback, useMemo, useRef, useState } from 'react';

import { GREY_COLOR, PRIMARY_COLOR } from '@constants/colors';
import debounce from '@utils/debounce';

import Input from '@uikit/Input/Input';
import Button from '@uikit/Button/Button';

import HeaderFiltersButtonWithTooltip from '@components/PageHeader/HeaderFiltersButtonWithTooltip/HeaderFiltersButtonWithTooltip';
import MoreActionsButton from '@components/HeaderTemplate/MoreActionsButton/MoreActionsButton';
import FiltersTooltipContentRaw from '@components/HeaderTemplate/HeaderTemplateFilters/FiltersTooltipContentRaw/FiltersTooltipContentRaw';
import FiltersTooltipContentFooter from '@components/HeaderTemplate/HeaderTemplateFilters/FiltersTooltipContentFooter/FiltersTooltipContentFooter';

import { IMoreActions } from '@components/HeaderTemplate/HeaderTemplate.model';
import useOutsideClick from '@hooks/useOutsideClick';

import './HeaderSearch.scss';

function HeaderSearch({
  selectedItems,
  handleRemoveItems,
  handleSearchValue,
  moreActions,
  initialFilterState,
  filterFields,
  appliedFilters,
  onApplyFilters,
}: {
  selectedItems?: any[];
  handleRemoveItems: () => void;
  handleSearchValue: (value: string) => void;
  moreActions?: IMoreActions[];
  initialFilterState: any;
  filterFields: any;
  appliedFilters: any;
  onApplyFilters: any;
}): JSX.Element {
  const confirmBtnRef = useRef<HTMLButtonElement>();
  const [filters, changeFilters] = useState(initialFilterState);
  const [searchString, changeSearchString] = useState('');
  const [isVisibleRemoveApproval, setVisibleRemoveApproval] = useState<boolean>(false);

  useOutsideClick(confirmBtnRef, () => setVisibleRemoveApproval(false));

  const debounedSetReduxSearchString = useCallback(
    debounce((value) => handleSearchValue(value), 1000),
    []
  );

  const handleSearchChange = ({ target: { value } }) => {
    changeSearchString(value);
    debounedSetReduxSearchString(value);
  };

  const handleClear = () => {
    changeSearchString('');
    handleSearchValue('');
  };

  const handleAddFilterInput = () =>
    changeFilters((prevState) => {
      const keys = Object.keys(prevState);
      const newKey = Number(keys[keys.length - 1]) + 1;

      return { ...prevState, [newKey]: initialFilterState['1'] };
    });

  const handleApplyFilters = () => {
    const filtersArray = Object.values(filters);

    if (filtersArray.some(({ value }) => value === '')) {
      return;
    }

    onApplyFilters(filtersArray);
  };

  const handleChangeFilterState = (filterRawKey, filterInputKey, value) => {
    changeFilters((prevState) => {
      return {
        ...prevState,
        [filterRawKey]: {
          ...prevState[filterRawKey],
          [filterInputKey]: value,
        },
      };
    });
  };

  const createHandleDeleteFilterRaw = (dummy: number) => () => {
    changeFilters((prevState) => {
      const newState = { ...prevState };
      delete newState[dummy];
      return newState;
    });
  };

  const areFiltersEnabled = useMemo(
    () => Object.values(appliedFilters).some(({ value }) => value.toString().length > 0),
    [appliedFilters]
  );

  return (
    <>
      <Input
        className="header__search"
        isClearable
        icon="search"
        iconColor={searchString ? PRIMARY_COLOR : GREY_COLOR}
        value={searchString}
        onChange={handleSearchChange}
        onClear={handleClear}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            e.stopPropagation();
          }
        }}
        placeholder="Search"
      />

      {selectedItems?.length ? <MoreActionsButton items={moreActions} /> : null}

      {selectedItems?.length > 0 &&
        (isVisibleRemoveApproval ? (
          <span ref={confirmBtnRef}>
            <Button leftIcon="trash" type="alert" onClick={handleRemoveItems}>
              Confirm
            </Button>
          </span>
        ) : (
          <Button leftIcon="trash" type="alert" onClick={() => setVisibleRemoveApproval(true)}>
            Remove
          </Button>
        ))}

      <HeaderFiltersButtonWithTooltip
        areFiltersEnabled={areFiltersEnabled}
        onClearAllFilters={() => {
          onApplyFilters([]);
          changeFilters(initialFilterState);
        }}
      >
        <div className="campaign-filters-tooltip-content">
          <div className="campaign-filters-tooltip-content__body">
            {Object.entries(filters).map((filter, index) => {
              const filterId = Number(filter[0]);
              const filterRawData = filter[1];

              return (
                <FiltersTooltipContentRaw
                  key={filterId}
                  rawId={filterId}
                  isAdditional={!!index}
                  onDeleteFilterInput={createHandleDeleteFilterRaw(filterId)}
                  filterRawData={filterRawData}
                  onChangeFilter={handleChangeFilterState}
                  filterFields={filterFields}
                />
              );
            })}
          </div>
          <FiltersTooltipContentFooter
            onAddFilterInput={handleAddFilterInput}
            onApplyFilters={handleApplyFilters}
          />
        </div>
      </HeaderFiltersButtonWithTooltip>
    </>
  );
}

export default HeaderSearch;
