import { SVGIcon } from '@uikit/Icon/Icon';
// eslint-disable-next-line import/no-extraneous-dependencies
import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import './OpportunityContactSidebarSection.scss';
import { Draggable } from 'react-beautiful-dnd';

type Props = {
  index: number;
  id: string;
  title: React.ReactNode;
  isInitialOpen: boolean;
  rightComponent?: React.ReactNode;
  children?: React.ReactNode;
  onOpen: () => void;
  showArrow: boolean;
};

const OpportunityContactSidebarSection = React.forwardRef(
  (
    { id, index, title, isInitialOpen, rightComponent, children, onOpen, showArrow }: Props,
    ref: { current: { setOpen: (open: boolean) => void } }
  ) => {
    const [isOpen, setOpen] = useState<boolean>(isInitialOpen);

    useEffect(() => {
      if (isOpen) {
        onOpen();
      }
    }, [isOpen]);

    useEffect(() => {
      if (!ref) {
        return;
      }

      ref.current = { setOpen };
    }, [ref]);

    useEffect(() => {
      if (!showArrow && isOpen) {
        setOpen(false);
      }
    }, [showArrow]);

    return (
      <Draggable draggableId={id} index={index}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            className="opportunity-contacts-sidebar-crm-section"
          >
            <div className="opportunity-contacts-sidebar-crm-section__header">
              <div
                {...provided.dragHandleProps}
                className="opportunity-contacts-sidebar-crm-section__drag-icon"
              >
                <SVGIcon icon="actionsTwoDots" size={10} color="#BDBDBDBA" />
              </div>

              <div
                className="opportunity-contacts-sidebar-crm-section__header-title"
                onClick={() => setOpen((open) => !open)}
              >
                {title}
              </div>
              {showArrow && (
                <div
                  className={cn('opportunity-contacts-sidebar-crm-section__header-collapse-btn', {
                    'opportunity-contacts-sidebar-crm-section__header-collapse-btn--open': isOpen,
                  })}
                  onClick={() => setOpen((open) => !open)}
                >
                  <SVGIcon icon="chevron" size={8} color="#7B7B7BB0" />
                </div>
              )}
              {rightComponent && (
                <div className="opportunity-contacts-sidebar-crm-section__header-right-component">
                  {rightComponent}
                </div>
              )}
            </div>

            <div
              className={cn('opportunity-contacts-sidebar-crm-section__content-container', {
                'opportunity-contacts-sidebar-crm-section__content-container--open': isOpen,
              })}
            >
              <div className="opportunity-contacts-sidebar-crm-section__content">{children}</div>
            </div>
          </div>
        )}
      </Draggable>
    );
  }
);

export default OpportunityContactSidebarSection;
