// package: inbox
// file: mailbox.proto

var mailbox_pb = require("./mailbox_pb");
var google_protobuf_empty_pb = require("google-protobuf/google/protobuf/empty_pb");
var common_pb = require("./common_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var MailboxApiService = (function () {
  function MailboxApiService() {}
  MailboxApiService.serviceName = "inbox.MailboxApiService";
  return MailboxApiService;
}());

MailboxApiService.getInboxEmailsOfCampaignPageable = {
  methodName: "getInboxEmailsOfCampaignPageable",
  service: MailboxApiService,
  requestStream: false,
  responseStream: true,
  requestType: common_pb.PageRequest,
  responseType: mailbox_pb.ThreadPreviewResponse
};

MailboxApiService.getSentEmailsOfCampaignPageable = {
  methodName: "getSentEmailsOfCampaignPageable",
  service: MailboxApiService,
  requestStream: false,
  responseStream: true,
  requestType: common_pb.PageRequest,
  responseType: mailbox_pb.ThreadPreviewResponse
};

MailboxApiService.getOutboxEmailsOfCampaignPageable = {
  methodName: "getOutboxEmailsOfCampaignPageable",
  service: MailboxApiService,
  requestStream: false,
  responseStream: true,
  requestType: common_pb.PageRequest,
  responseType: mailbox_pb.QueuePreviewResponse
};

MailboxApiService.getNeedAttentionEmailsOfCampaignPageable = {
  methodName: "getNeedAttentionEmailsOfCampaignPageable",
  service: MailboxApiService,
  requestStream: false,
  responseStream: true,
  requestType: common_pb.PageRequest,
  responseType: mailbox_pb.ThreadPreviewResponse
};

MailboxApiService.getThreadPreviews = {
  methodName: "getThreadPreviews",
  service: MailboxApiService,
  requestStream: false,
  responseStream: true,
  requestType: mailbox_pb.ThreadPreviewRequest,
  responseType: mailbox_pb.ThreadPreviewResponse
};

MailboxApiService.getThreadEmails = {
  methodName: "getThreadEmails",
  service: MailboxApiService,
  requestStream: false,
  responseStream: true,
  requestType: mailbox_pb.ThreadUidRequest,
  responseType: mailbox_pb.ThreadMessage
};

MailboxApiService.getThreadUnreadCounters = {
  methodName: "getThreadUnreadCounters",
  service: MailboxApiService,
  requestStream: false,
  responseStream: false,
  requestType: mailbox_pb.InboxUnreadCounterRequest,
  responseType: mailbox_pb.InboxUnreadCounterResponse
};

MailboxApiService.changeThreadsStatus = {
  methodName: "changeThreadsStatus",
  service: MailboxApiService,
  requestStream: false,
  responseStream: false,
  requestType: mailbox_pb.ThreadStatusRequest,
  responseType: google_protobuf_empty_pb.Empty
};

MailboxApiService.deleteThread = {
  methodName: "deleteThread",
  service: MailboxApiService,
  requestStream: false,
  responseStream: false,
  requestType: mailbox_pb.ThreadUidRequest,
  responseType: google_protobuf_empty_pb.Empty
};

MailboxApiService.getThreadPreviewByThreadId = {
  methodName: "getThreadPreviewByThreadId",
  service: MailboxApiService,
  requestStream: false,
  responseStream: false,
  requestType: mailbox_pb.ThreadUidRequest,
  responseType: mailbox_pb.ThreadPreviewResponse
};

MailboxApiService.getGlobalOutboxPreviewEmails = {
  methodName: "getGlobalOutboxPreviewEmails",
  service: MailboxApiService,
  requestStream: false,
  responseStream: true,
  requestType: mailbox_pb.ThreadPreviewRequest,
  responseType: mailbox_pb.OutboxPreviewResponse
};

MailboxApiService.getInboxAccountsPresetByWorkspaceId = {
  methodName: "getInboxAccountsPresetByWorkspaceId",
  service: MailboxApiService,
  requestStream: false,
  responseStream: true,
  requestType: common_pb.IdRequest,
  responseType: mailbox_pb.InboxAccountsPreset
};

MailboxApiService.changeViewStateByThreadId = {
  methodName: "changeViewStateByThreadId",
  service: MailboxApiService,
  requestStream: false,
  responseStream: false,
  requestType: mailbox_pb.ChangeViewStateRequest,
  responseType: google_protobuf_empty_pb.Empty
};

MailboxApiService.changeFavoriteState = {
  methodName: "changeFavoriteState",
  service: MailboxApiService,
  requestStream: false,
  responseStream: false,
  requestType: mailbox_pb.ThreadUidRequest,
  responseType: google_protobuf_empty_pb.Empty
};

MailboxApiService.assignToUser = {
  methodName: "assignToUser",
  service: MailboxApiService,
  requestStream: false,
  responseStream: false,
  requestType: mailbox_pb.AssignThreadToUserRequest,
  responseType: google_protobuf_empty_pb.Empty
};

MailboxApiService.assignMailsByEmailToPerson = {
  methodName: "assignMailsByEmailToPerson",
  service: MailboxApiService,
  requestStream: false,
  responseStream: false,
  requestType: mailbox_pb.AssignMailsToPersonRequest,
  responseType: google_protobuf_empty_pb.Empty
};

MailboxApiService.getRelatedThreadPreviews = {
  methodName: "getRelatedThreadPreviews",
  service: MailboxApiService,
  requestStream: false,
  responseStream: true,
  requestType: mailbox_pb.RelatedThreadPreviewRequest,
  responseType: mailbox_pb.RelatedThreadPreviewResponse
};

MailboxApiService.getPreviewEmailsByPersonIdIn = {
  methodName: "getPreviewEmailsByPersonIdIn",
  service: MailboxApiService,
  requestStream: false,
  responseStream: true,
  requestType: common_pb.IdContainer,
  responseType: mailbox_pb.ThreadPreviewResponse
};

MailboxApiService.getLatestEmailByThreadId = {
  methodName: "getLatestEmailByThreadId",
  service: MailboxApiService,
  requestStream: false,
  responseStream: false,
  requestType: mailbox_pb.ThreadUidRequest,
  responseType: mailbox_pb.ThreadMessage
};

MailboxApiService.cancelAllQueuesByRelatedIdInAndReCalculateQueues = {
  methodName: "cancelAllQueuesByRelatedIdInAndReCalculateQueues",
  service: MailboxApiService,
  requestStream: false,
  responseStream: false,
  requestType: mailbox_pb.MailboxQueueCancelRequest,
  responseType: google_protobuf_empty_pb.Empty
};

MailboxApiService.getAllEmailsByContactId = {
  methodName: "getAllEmailsByContactId",
  service: MailboxApiService,
  requestStream: false,
  responseStream: true,
  requestType: common_pb.IdRequest,
  responseType: mailbox_pb.OutboxThreadMessage
};

MailboxApiService.getAllFutureQueueByContactIdAndCampaignId = {
  methodName: "getAllFutureQueueByContactIdAndCampaignId",
  service: MailboxApiService,
  requestStream: false,
  responseStream: true,
  requestType: common_pb.IdRequest,
  responseType: mailbox_pb.QueueMessageResponse
};

MailboxApiService.getFutureByQueueId = {
  methodName: "getFutureByQueueId",
  service: MailboxApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: mailbox_pb.QueueMessageResponse
};

MailboxApiService.updateQueueById = {
  methodName: "updateQueueById",
  service: MailboxApiService,
  requestStream: false,
  responseStream: false,
  requestType: mailbox_pb.QueueMessageUpdateRequest,
  responseType: mailbox_pb.QueueMessageResponse
};

MailboxApiService.addBlackListPattern = {
  methodName: "addBlackListPattern",
  service: MailboxApiService,
  requestStream: false,
  responseStream: false,
  requestType: mailbox_pb.BlackListRequest,
  responseType: google_protobuf_empty_pb.Empty
};

MailboxApiService.addBlackListPatterns = {
  methodName: "addBlackListPatterns",
  service: MailboxApiService,
  requestStream: false,
  responseStream: false,
  requestType: mailbox_pb.BlackListBatchRequest,
  responseType: google_protobuf_empty_pb.Empty
};

MailboxApiService.updateBlackListPattern = {
  methodName: "updateBlackListPattern",
  service: MailboxApiService,
  requestStream: false,
  responseStream: false,
  requestType: mailbox_pb.BlackListRequest,
  responseType: google_protobuf_empty_pb.Empty
};

MailboxApiService.removeBlackListPatternBulk = {
  methodName: "removeBlackListPatternBulk",
  service: MailboxApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdContainer,
  responseType: google_protobuf_empty_pb.Empty
};

MailboxApiService.exportBlackListPatternsToCsv = {
  methodName: "exportBlackListPatternsToCsv",
  service: MailboxApiService,
  requestStream: false,
  responseStream: false,
  requestType: mailbox_pb.ExportBlackListPatternRequest,
  responseType: common_pb.FileResponse
};

MailboxApiService.getAllBlackListPatternsOfOrganization = {
  methodName: "getAllBlackListPatternsOfOrganization",
  service: MailboxApiService,
  requestStream: false,
  responseStream: true,
  requestType: mailbox_pb.GetUnsubscribedPatternPageable,
  responseType: mailbox_pb.BlackListResponse
};

MailboxApiService.addThreadTag = {
  methodName: "addThreadTag",
  service: MailboxApiService,
  requestStream: false,
  responseStream: false,
  requestType: mailbox_pb.ThreadTag,
  responseType: mailbox_pb.ThreadTag
};

MailboxApiService.updateThreadTag = {
  methodName: "updateThreadTag",
  service: MailboxApiService,
  requestStream: false,
  responseStream: false,
  requestType: mailbox_pb.ThreadTag,
  responseType: mailbox_pb.ThreadTag
};

MailboxApiService.removeThreadTag = {
  methodName: "removeThreadTag",
  service: MailboxApiService,
  requestStream: false,
  responseStream: false,
  requestType: mailbox_pb.ThreadTag,
  responseType: google_protobuf_empty_pb.Empty
};

MailboxApiService.getAllThreadTags = {
  methodName: "getAllThreadTags",
  service: MailboxApiService,
  requestStream: false,
  responseStream: true,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: mailbox_pb.ThreadTag
};

MailboxApiService.getAllAIAssistantActions = {
  methodName: "getAllAIAssistantActions",
  service: MailboxApiService,
  requestStream: false,
  responseStream: false,
  requestType: google_protobuf_empty_pb.Empty,
  responseType: mailbox_pb.MailAIAssistantContainer
};

MailboxApiService.executeAIAssistantAction = {
  methodName: "executeAIAssistantAction",
  service: MailboxApiService,
  requestStream: false,
  responseStream: false,
  requestType: mailbox_pb.MailAIAssistantRequest,
  responseType: mailbox_pb.MailAIAssistantResponse
};

MailboxApiService.createOrUpdateThreadDraft = {
  methodName: "createOrUpdateThreadDraft",
  service: MailboxApiService,
  requestStream: false,
  responseStream: false,
  requestType: mailbox_pb.MailThreadDraftRequest,
  responseType: google_protobuf_empty_pb.Empty
};

MailboxApiService.deleteThreadDraft = {
  methodName: "deleteThreadDraft",
  service: MailboxApiService,
  requestStream: false,
  responseStream: false,
  requestType: mailbox_pb.ThreadUidRequest,
  responseType: google_protobuf_empty_pb.Empty
};

MailboxApiService.getThreadDraft = {
  methodName: "getThreadDraft",
  service: MailboxApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: mailbox_pb.MailThreadDraftRequest
};

MailboxApiService.getAllThreadConversationMessages = {
  methodName: "getAllThreadConversationMessages",
  service: MailboxApiService,
  requestStream: false,
  responseStream: true,
  requestType: mailbox_pb.ThreadUidRequest,
  responseType: mailbox_pb.ThreadConversationMessageResponse
};

MailboxApiService.createOrUpdateThreadConversationMessage = {
  methodName: "createOrUpdateThreadConversationMessage",
  service: MailboxApiService,
  requestStream: false,
  responseStream: false,
  requestType: mailbox_pb.ThreadConversationMessageRequest,
  responseType: mailbox_pb.ThreadConversationMessageResponse
};

MailboxApiService.deleteThreadConversationMessage = {
  methodName: "deleteThreadConversationMessage",
  service: MailboxApiService,
  requestStream: false,
  responseStream: false,
  requestType: common_pb.IdRequest,
  responseType: google_protobuf_empty_pb.Empty
};

exports.MailboxApiService = MailboxApiService;

function MailboxApiServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

MailboxApiServiceClient.prototype.getInboxEmailsOfCampaignPageable = function getInboxEmailsOfCampaignPageable(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MailboxApiService.getInboxEmailsOfCampaignPageable, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MailboxApiServiceClient.prototype.getSentEmailsOfCampaignPageable = function getSentEmailsOfCampaignPageable(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MailboxApiService.getSentEmailsOfCampaignPageable, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MailboxApiServiceClient.prototype.getOutboxEmailsOfCampaignPageable = function getOutboxEmailsOfCampaignPageable(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MailboxApiService.getOutboxEmailsOfCampaignPageable, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MailboxApiServiceClient.prototype.getNeedAttentionEmailsOfCampaignPageable = function getNeedAttentionEmailsOfCampaignPageable(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MailboxApiService.getNeedAttentionEmailsOfCampaignPageable, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MailboxApiServiceClient.prototype.getThreadPreviews = function getThreadPreviews(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MailboxApiService.getThreadPreviews, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MailboxApiServiceClient.prototype.getThreadEmails = function getThreadEmails(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MailboxApiService.getThreadEmails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MailboxApiServiceClient.prototype.getThreadUnreadCounters = function getThreadUnreadCounters(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MailboxApiService.getThreadUnreadCounters, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MailboxApiServiceClient.prototype.changeThreadsStatus = function changeThreadsStatus(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MailboxApiService.changeThreadsStatus, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MailboxApiServiceClient.prototype.deleteThread = function deleteThread(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MailboxApiService.deleteThread, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MailboxApiServiceClient.prototype.getThreadPreviewByThreadId = function getThreadPreviewByThreadId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MailboxApiService.getThreadPreviewByThreadId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MailboxApiServiceClient.prototype.getGlobalOutboxPreviewEmails = function getGlobalOutboxPreviewEmails(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MailboxApiService.getGlobalOutboxPreviewEmails, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MailboxApiServiceClient.prototype.getInboxAccountsPresetByWorkspaceId = function getInboxAccountsPresetByWorkspaceId(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MailboxApiService.getInboxAccountsPresetByWorkspaceId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MailboxApiServiceClient.prototype.changeViewStateByThreadId = function changeViewStateByThreadId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MailboxApiService.changeViewStateByThreadId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MailboxApiServiceClient.prototype.changeFavoriteState = function changeFavoriteState(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MailboxApiService.changeFavoriteState, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MailboxApiServiceClient.prototype.assignToUser = function assignToUser(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MailboxApiService.assignToUser, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MailboxApiServiceClient.prototype.assignMailsByEmailToPerson = function assignMailsByEmailToPerson(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MailboxApiService.assignMailsByEmailToPerson, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MailboxApiServiceClient.prototype.getRelatedThreadPreviews = function getRelatedThreadPreviews(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MailboxApiService.getRelatedThreadPreviews, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MailboxApiServiceClient.prototype.getPreviewEmailsByPersonIdIn = function getPreviewEmailsByPersonIdIn(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MailboxApiService.getPreviewEmailsByPersonIdIn, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MailboxApiServiceClient.prototype.getLatestEmailByThreadId = function getLatestEmailByThreadId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MailboxApiService.getLatestEmailByThreadId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MailboxApiServiceClient.prototype.cancelAllQueuesByRelatedIdInAndReCalculateQueues = function cancelAllQueuesByRelatedIdInAndReCalculateQueues(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MailboxApiService.cancelAllQueuesByRelatedIdInAndReCalculateQueues, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MailboxApiServiceClient.prototype.getAllEmailsByContactId = function getAllEmailsByContactId(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MailboxApiService.getAllEmailsByContactId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MailboxApiServiceClient.prototype.getAllFutureQueueByContactIdAndCampaignId = function getAllFutureQueueByContactIdAndCampaignId(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MailboxApiService.getAllFutureQueueByContactIdAndCampaignId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MailboxApiServiceClient.prototype.getFutureByQueueId = function getFutureByQueueId(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MailboxApiService.getFutureByQueueId, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MailboxApiServiceClient.prototype.updateQueueById = function updateQueueById(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MailboxApiService.updateQueueById, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MailboxApiServiceClient.prototype.addBlackListPattern = function addBlackListPattern(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MailboxApiService.addBlackListPattern, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MailboxApiServiceClient.prototype.addBlackListPatterns = function addBlackListPatterns(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MailboxApiService.addBlackListPatterns, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MailboxApiServiceClient.prototype.updateBlackListPattern = function updateBlackListPattern(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MailboxApiService.updateBlackListPattern, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MailboxApiServiceClient.prototype.removeBlackListPatternBulk = function removeBlackListPatternBulk(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MailboxApiService.removeBlackListPatternBulk, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MailboxApiServiceClient.prototype.exportBlackListPatternsToCsv = function exportBlackListPatternsToCsv(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MailboxApiService.exportBlackListPatternsToCsv, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MailboxApiServiceClient.prototype.getAllBlackListPatternsOfOrganization = function getAllBlackListPatternsOfOrganization(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MailboxApiService.getAllBlackListPatternsOfOrganization, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MailboxApiServiceClient.prototype.addThreadTag = function addThreadTag(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MailboxApiService.addThreadTag, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MailboxApiServiceClient.prototype.updateThreadTag = function updateThreadTag(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MailboxApiService.updateThreadTag, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MailboxApiServiceClient.prototype.removeThreadTag = function removeThreadTag(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MailboxApiService.removeThreadTag, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MailboxApiServiceClient.prototype.getAllThreadTags = function getAllThreadTags(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MailboxApiService.getAllThreadTags, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MailboxApiServiceClient.prototype.getAllAIAssistantActions = function getAllAIAssistantActions(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MailboxApiService.getAllAIAssistantActions, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MailboxApiServiceClient.prototype.executeAIAssistantAction = function executeAIAssistantAction(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MailboxApiService.executeAIAssistantAction, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MailboxApiServiceClient.prototype.createOrUpdateThreadDraft = function createOrUpdateThreadDraft(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MailboxApiService.createOrUpdateThreadDraft, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MailboxApiServiceClient.prototype.deleteThreadDraft = function deleteThreadDraft(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MailboxApiService.deleteThreadDraft, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MailboxApiServiceClient.prototype.getThreadDraft = function getThreadDraft(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MailboxApiService.getThreadDraft, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MailboxApiServiceClient.prototype.getAllThreadConversationMessages = function getAllThreadConversationMessages(requestMessage, metadata) {
  var listeners = {
    data: [],
    end: [],
    status: []
  };
  var client = grpc.invoke(MailboxApiService.getAllThreadConversationMessages, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onMessage: function (responseMessage) {
      listeners.data.forEach(function (handler) {
        handler(responseMessage);
      });
    },
    onEnd: function (status, statusMessage, trailers) {
      listeners.status.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners.end.forEach(function (handler) {
        handler({ code: status, details: statusMessage, metadata: trailers });
      });
      listeners = null;
    }
  });
  return {
    on: function (type, handler) {
      listeners[type].push(handler);
      return this;
    },
    cancel: function () {
      listeners = null;
      client.close();
    }
  };
};

MailboxApiServiceClient.prototype.createOrUpdateThreadConversationMessage = function createOrUpdateThreadConversationMessage(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MailboxApiService.createOrUpdateThreadConversationMessage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

MailboxApiServiceClient.prototype.deleteThreadConversationMessage = function deleteThreadConversationMessage(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(MailboxApiService.deleteThreadConversationMessage, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.MailboxApiServiceClient = MailboxApiServiceClient;

