import { useInfiniteQuery, useQueryClient } from 'react-query';
import { QueryPageExtendedHookType } from '@ts/common.types';
import { useMemo, useCallback } from 'react';
import { PipelineOpportunityType } from '@ts/relationshipPipeline.types';
import { getStageOpportunitiesPageApi } from '@api/relationshipPipeline';

export const DEALS_PAGE_LIMIT = 10;
export const DEALS_WITHOUT_FILTERS_QUERY_KEY = 'relationship-stage-opportunities';

export default (
  workspaceId: number,
  pipelineId: number,
  stageId: number
): QueryPageExtendedHookType<PipelineOpportunityType> => {
  const queryClient = useQueryClient();

  const composeKeyByStage = (reqStageId: number) => [
    DEALS_WITHOUT_FILTERS_QUERY_KEY,
    pipelineId,
    workspaceId,
    reqStageId,
  ];
  const key = useMemo(() => composeKeyByStage(stageId), [stageId]);

  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage, refetch } =
    useInfiniteQuery<PipelineOpportunityType[]>(
      key,
      ({ pageParam = 0 }) => {
        return getStageOpportunitiesPageApi(stageId, workspaceId, pageParam, DEALS_PAGE_LIMIT);
      },
      {
        getNextPageParam: (lastPage: PipelineOpportunityType[], allPages) => {
          if (lastPage?.length < DEALS_PAGE_LIMIT) {
            return false;
          }
          return allPages.length;
        },
        enabled: false,
        retry: 1,
        refetchOnWindowFocus: false,
        refetchInterval: 5 * 60 * 1000,
      }
    );

  const removeFromCacheById = (id: number) =>
    queryClient.setQueryData(key, ({ pages, pageParams }) => {
      const updatePages = pages.map((page) =>
        page.filter((item: PipelineOpportunityType) => item.id !== id)
      );

      return {
        pageParams,
        pages: updatePages,
      };
    });

  const addToCache = (item: PipelineOpportunityType) =>
    queryClient.setQueryData(key, (cachedData) => {
      // @ts-ignore
      if (cachedData?.pages == null || cachedData?.pages.length === 0) {
        return {
          pageParams: undefined,
          pages: [[item]],
        };
      }
      // @ts-ignore
      const { pages, pageParams } = cachedData;
      const updatedPages = [...pages];
      updatedPages[updatedPages.length - 1] = [...updatedPages[updatedPages.length - 1], item];

      return {
        pageParams,
        pages: updatedPages,
      };
    });

  const addCollectionToCache = (items: PipelineOpportunityType[]) =>
    // we should add it only if the cache is empty to prevent duplicating
    queryClient.setQueryData(key, (cachedData) => {
      // @ts-ignore
      if (cachedData?.pages == null || cachedData?.pages.length === 0) {
        return {
          pageParams: undefined,
          pages: [items],
        };
      }

      return cachedData;
    });

  const updateInCache = (id: number, payload: PipelineOpportunityType) =>
    queryClient.setQueryData(key, ({ pages, pageParams }) => {
      const updatePages = pages.map((page) =>
        page.map((item: PipelineOpportunityType) => {
          if (item.id === id) return payload;
          return item;
        })
      );

      return {
        pageParams,
        pages: updatePages,
      };
    });

  const evictCacheByKey = useCallback(
    (reqPipelineId: number) => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === DEALS_WITHOUT_FILTERS_QUERY_KEY &&
          query.queryKey[1] === reqPipelineId,
      });
    },
    [queryClient, key]
  );

  const resetCache = () => {
    queryClient.invalidateQueries(key);
  };

  return {
    items: data?.pages?.flat(),
    refetch,
    isLoading,
    isLoadingNextPage: isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    removeItem: removeFromCacheById,
    addItem: addToCache,
    addItems: addCollectionToCache,
    updateItem: updateInCache,
    evictByKey: evictCacheByKey,
    resetCache,
  };
};
