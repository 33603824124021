import React from 'react';
import { useQueryClient } from 'react-query';

import SidebarCRM, {
  sidebarSize,
  ContentDataType,
  GeneralDataType,
} from '@components/SidebarCRM/SidebarCRM';
import { SECTION_TYPE } from '@components/SidebarCRM/SidebarCRMContent/SidebarCRMContent';
import { personStatusOptions } from '@components/SidebarCRM/sections/SidebarCRMSectionContact/SidebarCRMSectionContact';

import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';

import { WebsiteType } from '@ts/company.types';
import { PersonBaseType, RelationshipStatusType } from '@ts/people.types';

import {
  addCompanyNoteApi,
  addCompanyTagApi,
  createOrUpdateCompanyApi,
  deleteMetricsValueByIdApi,
  fetchPeopleCompanyNotesApi,
  getAllCompanyTagsApi,
  getAllMetricsViewSettingsByCompanyIdApi,
  getCampaignOpportunitiesByWebsiteIdApi,
  getPipelineOpportunitiesByWebsiteIdApi,
  getTagsByCompanyIdApi,
  getWebsiteByIdApi,
  loadMetricsValueByIdApi,
  removeCompanyNoteApi,
  removeCompanyTagApi,
  setMetricsValueByIdApi,
  showMetricsByTypeApi,
  updateCompanyNoteApi,
  updateWebsiteStatus,
} from '@api/website.api';
import { getPreviewEmailsApi } from '@api/mailbox.api';
import {
  CustomVariableValueRelatedTo,
  PeopleRelationshipStatusMap,
} from 'respona_api/generated/common_pb';
import {
  createOrUpdateCustomVariableValueApi,
  getCustomVariableValuesApi,
} from '@api/customVariables.api';
import {
  removePersonApi,
  removePersonEmailApi,
  updatePersonApi,
  updatePersonEmailApi,
  updatePersonStatus,
} from '@api/people.api';
import { OpportunityPersonContactType } from '@ts/contact.types';
import { PeopleWebsiteMetricsResponse } from 'respona_api/generated/people-website_pb';
import { assignStageOpportunityApi, moveStageOpportunityApi } from '@api/relationshipPipeline';
import {
  PipelineOpportunityType,
  RelationshipPipelineStageType,
} from '@ts/relationshipPipeline.types';
import { CustomVariableValueType } from '@ts/customVariales.types';

type Props = {
  isOpen: boolean;
  itemId: number;
  onClose?: () => void;
  data: {
    logoUrl: string;
    title: string;
    domain: string;
    status: PeopleRelationshipStatusMap[keyof PeopleRelationshipStatusMap];
    contactsList: OpportunityPersonContactType[];
    metricsList: PeopleWebsiteMetricsResponse.AsObject[];
    createdAt: number;
  };
  tableQueryKey: any[];
  size?: sidebarSize;
  disabledFields?: any;
};

function WebsitesSidebar({
  isOpen,
  itemId,
  onClose,
  data,
  tableQueryKey,
  size = sidebarSize.NORMAL,
  disabledFields = {},
}: Props): JSX.Element {
  const queryClient = useQueryClient();
  const workspaceId = useCurrentWorkspaceId();

  if (!data) {
    return null;
  }

  const headerData = {
    logoUrl: data.logoUrl,
    title: data.title,
    url: data.domain,
  };

  const mapContentData: GeneralDataType[] = [
    {
      key: 'websiteName',
      title: 'Website name',
      value: data.title,
      type: ContentDataType.text,
      isEditable: true,
      onChange: (title: string) => {
        createOrUpdateCompanyApi({
          id: itemId,
          title,
          domain: headerData.url,
          workspaceId,
        });
      },
    },
    {
      key: 'websiteDomain',
      title: 'Website domain',
      value: data.domain,
      type: ContentDataType.text,
      isEditable: true,
      onChange: (domain: string) => {
        createOrUpdateCompanyApi({
          id: itemId,
          title: headerData.title,
          domain,
          workspaceId,
        });
      },
    },
    {
      key: 'relationshipStatus',
      title: 'Relationship status',
      value: data.status,
      type: ContentDataType.select,
      isEditable: true,
      options: personStatusOptions,
      onChange: (status: RelationshipStatusType) => {
        updateWebsiteStatus({ id: itemId, status }).then((err) => console.log(err));
      },
    },
    {
      key: 'createdOn',
      title: 'Created on',
      value: data.createdAt,
      transformCreatedAt: true,
      type: ContentDataType.text,
      isEditable: false,
    },
  ];

  const QUERY_KEY_BASE = 'websites-sidebar';
  const updateLocalWrapper = (cb: (prevData: WebsiteType) => WebsiteType) => {
    queryClient.setQueryData<WebsiteType>([`${QUERY_KEY_BASE}-website`, itemId, workspaceId], cb);
    queryClient.setQueryData<WebsiteType[]>(tableQueryKey, (websites: WebsiteType[]) => {
      return websites.map((website) => {
        if (website.id === itemId) {
          return cb(website);
        }

        return website;
      });
    });
  };
  const onSuccessShowMetricByType = (res, variables) => {
    updateLocalWrapper((prevData: WebsiteType) => {
      return {
        ...prevData,
        metricsList: variables.enabled
          ? [...prevData.metricsList, res]
          : prevData.metricsList.filter((d) => d.type !== variables.type),
      };
    });
  };
  const onSuccessDeleteMetricValue = (_, variables) => {
    updateLocalWrapper((prevData: WebsiteType) => {
      return {
        ...prevData,
        metricsList: prevData.metricsList.filter((item) => item.id !== variables.id),
      };
    });
  };
  const onSuccessSetMetric = (_, variables) => {
    updateLocalWrapper((prevData: WebsiteType) => {
      return {
        ...prevData,
        metricsList: prevData.metricsList.map((metric) => {
          if (metric.id !== variables.data.id) {
            return metric;
          }

          return { ...variables.data };
        }),
      };
    });
  };
  const onUpdatedContact = (
    key: keyof PersonBaseType,
    value: string,
    updatedContact: PersonBaseType
  ) => {
    updatePersonApi({
      ...updatedContact,
      [key]: value,
      workspaceId,
    });
    updateLocalWrapper((prevData: WebsiteType) => {
      return {
        ...prevData,
        contactsList: prevData.contactsList.map((contact) => {
          if (contact.id !== updatedContact.id) {
            return contact;
          }

          return { ...contact, [key]: value };
        }),
      };
    });
  };
  const onUpdatedStatus = (contactId: number, status: RelationshipStatusType) => {
    updatePersonStatus(contactId, status);
    updateLocalWrapper((prevData: WebsiteType) => {
      return {
        ...prevData,
        contactsList: prevData.contactsList.map((contact) => {
          if (contact.id !== contactId) {
            return contact;
          }

          return { ...contact, status };
        }),
      };
    });
  };
  const onSaveEmail = (contactId: number, initialValue: string, email: string) => {
    updatePersonEmailApi(contactId, initialValue, email);
    updateLocalWrapper((prevData: WebsiteType) => {
      return {
        ...prevData,
        contactsList: prevData.contactsList.map((contact) => {
          if (contact.id !== contactId) {
            return contact;
          }

          return {
            ...contact,
            emailsList: contact.emailsList.map((item) => {
              if (item.email !== initialValue) {
                return item;
              }

              return { ...item, email };
            }),
          };
        }),
      };
    });
  };
  const onRemovedEmail = (contactId: number, email: string) => {
    removePersonEmailApi(contactId, email);
    updateLocalWrapper((prevData: WebsiteType) => {
      return {
        ...prevData,
        contactsList: prevData.contactsList.map((contact) => {
          if (contact.id !== contactId) {
            return contact;
          }

          return {
            ...contact,
            emailsList: contact.emailsList.filter((item) => item.email !== email),
          };
        }),
      };
    });
  };
  const onRemovedContact = (contactId: number) => {
    removePersonApi(contactId);
    updateLocalWrapper((prevData: WebsiteType) => {
      return {
        ...prevData,
        contactsList: prevData.contactsList.filter((contact) => {
          return contact.id !== contactId;
        }),
      };
    });
  };
  const onGetEmail = (payloadId: number) => {
    return getPreviewEmailsApi(workspaceId, null, payloadId);
  };
  const onCreatedContact = (contact: PersonBaseType) => {
    updateLocalWrapper((prevData: WebsiteType) => {
      return {
        ...prevData,
        contactsList: [
          ...prevData.contactsList,
          {
            id: contact.id,
            name: contact.name,
            jobPosition: contact.jobPosition,
            avatarUrl: contact.avatarUrl,
            emailsList: contact.emailsList,
            socialLinksList: contact.socialLinksList,
            status: contact.status,
            ownerId: contact.ownerId,
            totalItems: prevData.totalItems + 1,
            workspaceId: contact.workspaceId,
          },
        ],
      };
    });
  };

  const assignStageOpportunity = (
    userId: number,
    opportunityId: number
  ): Promise<RelationshipPipelineStageType> => {
    return assignStageOpportunityApi(opportunityId, workspaceId, userId);
  };

  const onMoveOpportunity = (
    stageId: number,
    opportunityId: number,
    pipelineId: number
  ): Promise<PipelineOpportunityType> =>
    moveStageOpportunityApi(workspaceId, pipelineId, stageId, opportunityId);

  const sectionsMeta = {
    [SECTION_TYPE.CONTACTS]: {
      queryKeyWebsite: `${QUERY_KEY_BASE}-website`,
      getWebsite: getWebsiteByIdApi,
      updateContact: onUpdatedContact,
      onCreatedContact,
      updateStatus: onUpdatedStatus,
      removeEmail: onRemovedEmail,
      saveEmail: onSaveEmail,
      removeContact: onRemovedContact,
    },
    [SECTION_TYPE.TAGS]: {
      queryKey: `${QUERY_KEY_BASE}-tags`,
      getAllTags: getAllCompanyTagsApi,
      getAddedTags: getTagsByCompanyIdApi,
      addTag: addCompanyTagApi,
      removeTag: removeCompanyTagApi,
    },
    [SECTION_TYPE.EMAILS]: {
      queryKey: `${QUERY_KEY_BASE}-emails`,
      title: 'Related conversations',
      getEmails: onGetEmail,
    },
    [SECTION_TYPE.CAMPAIGNS]: {
      queryKey: `${QUERY_KEY_BASE}-campaigns`,
      getOpportunities: getCampaignOpportunitiesByWebsiteIdApi,
    },
    [SECTION_TYPE.NOTES]: {
      queryKey: `${QUERY_KEY_BASE}-notes`,
      getNote: fetchPeopleCompanyNotesApi,
      addNote: addCompanyNoteApi,
      updateNote: updateCompanyNoteApi,
      removeNote: removeCompanyNoteApi,
    },
    [SECTION_TYPE.METRICS]: {
      queryKey: `${QUERY_KEY_BASE}-metrics`,
      queryKeyWebsite: `${QUERY_KEY_BASE}-website`,
      getWebsite: getWebsiteByIdApi,
      getAllMetricsViewSettings: getAllMetricsViewSettingsByCompanyIdApi,
      setMetricValue: setMetricsValueByIdApi,
      onSuccessSetMetric,
      deleteMetricValue: deleteMetricsValueByIdApi,
      onSuccessDeleteMetricValue,
      showMetricByType: showMetricsByTypeApi,
      onSuccessShowMetricByType,
      loadMetricValueById: loadMetricsValueByIdApi,
    },
    [SECTION_TYPE.VARIABLES]: {
      queryKey: `${QUERY_KEY_BASE}-variables`,
      relatedTo: CustomVariableValueRelatedTo.WEBSITE,
      getVariables: getCustomVariableValuesApi,
      createOrUpdateVariable: createOrUpdateCustomVariableValueApi,
    },
    [SECTION_TYPE.OPPORTUNITIES]: {
      queryKey: `${QUERY_KEY_BASE}-opportunities`,
      title: 'Deals',
      getOpportunities: getPipelineOpportunitiesByWebsiteIdApi,
      createOrUpdateCustomVariableValue(newVariable) {
        queryClient.setQueryData(
          ['pipelines-sidebar-variables', newVariable.relatedId],
          (data: CustomVariableValueType[]) => {
            if (!data) {
              return [newVariable];
            }
            const existingIndex = data.findIndex(
              (item) => item.variableId === newVariable.variableId
            );
            if (existingIndex === -1) {
              return [...data, newVariable];
            }
            return data.map((item, ind) => (ind === existingIndex ? newVariable : item));
          }
        );
        return createOrUpdateCustomVariableValueApi(newVariable);
      },
      assignUser: assignStageOpportunity,
      moveOpportunity: onMoveOpportunity,
      isDisabledDomain: disabledFields.isDisabledDomain,
      isDisabledWebsiteName: disabledFields.isDisabledWebsiteName,
    },
  };

  return (
    <div>
      <SidebarCRM
        type="websites"
        itemId={itemId}
        isOpen={isOpen}
        onClose={onClose}
        headerData={headerData}
        contentData={mapContentData}
        sectionsMeta={sectionsMeta}
        size={size}
      />
    </div>
  );
}

export default WebsitesSidebar;
