import React, { useEffect, useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import { DispatchType } from 'src/store';
import { TagType } from '@ts/common.types';

import {
  addCompanyTagApi,
  getAllCompanyTagsApi,
  removeCompanyTagApi,
} from '@api/website.api';

import { addNotification } from '@redux/actions/notifications.actions';

import { SVGIcon } from '@uikit/Icon/Icon';
import TooltipSearchableContent from '@uikit/TooltipSearchableContent/TooltipSearchableContent';

import './OpportunityContactSidebarSectionTags.scss';
import OpportunityContactSidebarSection from '@components/CampaignSettings/OpportunityContactSidebar/_components/OpportunityContactSidebarSection/OpportunityContactSidebarSection';
import {OpportunityDetailsType, OpportunitySidebarActionProps, OpportunitySidebarProps} from '@ts/opportunity.types';
import Loader from '@uikit/Loader/Loader';

const Tag = ({ title, onRemove }: { title: string; onRemove: () => void }) => (
  <div className="opportunity-contacts-sidebar-section-tags__tag">
    <span>{title}</span>
    <span className="opportunity-contacts-sidebar-section-tags__tag-delete-btn" onClick={onRemove}>
      <SVGIcon icon="crossDelete" color="#7B7B7B" size={15} />
    </span>
  </div>
);

const OpportunityContactSidebarSectionTags = ({
  opportunity,
  index,
}: OpportunitySidebarProps) => {
  const { website } = opportunity;

  const { tagsList: tags } = website;
  const ref = useRef(null);
  const dispatch = useDispatch<DispatchType>();
  const queryClient = useQueryClient();
  const [isOpen, setOpen] = useState(false);

  const { data: allTags, isLoading: isLoadingTags } = useQuery<TagType[]>(
    ['contacts-sidebar-websites-tags', 'current-tags'],
    () => getAllCompanyTagsApi(),
    {
      enabled: !!opportunity && !!opportunity.id,
      refetchOnWindowFocus: false,
      staleTime: 5 * (60 * 1000),
    }
  );

  useEffect(() => {
    if (tags.length > 0) {
      setOpen(true);
    }
  }, [tags]);

  const handleToggleTag = (tagId, tagTitle, selected = true) => {
    if (selected) {
      addCompanyTagApi(tagId, tagTitle, website.id).then((res: TagType) => {
        queryClient.setQueryData(
          ['active-opportunity', opportunity.id],
          (cachedOpp: OpportunityDetailsType) => ({
            ...cachedOpp,
            website: {
              ...cachedOpp.website,
              tagsList: [...(cachedOpp.website?.tagsList || []), res],
            },
          })
        );
        dispatch(addNotification({ title: 'Tag added', type: 'success' }));
      });
    } else {
      removeCompanyTagApi(tagId, website.id).then(() => {
        queryClient.setQueryData(
          ['active-opportunity', opportunity.id],
          (cachedOpp: OpportunityDetailsType) => ({
            ...cachedOpp,
            website: {
              ...cachedOpp.website,
              tagsList: (cachedOpp.website?.tagsList || [])
                .slice()
                .filter((item: TagType) => item.id !== tagId),
            },
          })
        );
        dispatch(addNotification({ title: 'Tag removed', type: 'success' }));
      });
    }
  };

  const handleCreateTag = (title: string) => {
    addCompanyTagApi(null, title, website.id).then((res) => {
      queryClient.setQueryData<TagType[]>(
        ['contacts-sidebar-websites-tags', 'current-tags'],
        (prevTags) => [...prevTags, res]
      );

      queryClient.setQueryData(
        ['active-opportunity', opportunity.id],
        (cachedOpp: OpportunityDetailsType) => ({
          ...cachedOpp,
          website: {
            ...cachedOpp.website,
            tagsList: [...(cachedOpp.website?.tagsList || []), res],
          },
        })
      );
      dispatch(addNotification({ title: 'Tag added', type: 'success' }));
    });
  };

  return (
    <OpportunityContactSidebarSection
      ref={ref}
      index={index}
      id="tags"
      title="Tags"
      showArrow={tags?.length > 0}
      onOpen={() => setOpen(!isOpen)}
      isInitialOpen={tags?.length > 0}
      rightComponent={
        website == null || website.id <= 0 ? null : (
          <div
            className="opportunity-contacts-sidebar-crm__content__add-btn"
            data-for="opportunity-contacts-sidebar-crm-tags"
            data-tip=""
          >
            <SVGIcon icon="plusIcon" size={10} color="#221CB6" />
            Add
          </div>
        )
      }
    >
      <Loader isLoading={isLoadingTags}>
        <div className="opportunity-contacts-sidebar-section-tags">
          {tags?.length ? (
            tags?.map((item: TagType) => (
              <Tag
                key={item.id}
                title={item.title}
                onRemove={() => handleToggleTag(item.id, item.title, false)}
              />
            ))
          ) : (
            <div className="opportunity-contacts-sidebar-section-tags--empty">Empty</div>
          )}
        </div>
      </Loader>
      <ReactTooltip
        id="opportunity-contacts-sidebar-crm-tags"
        className="react-tooltip"
        place="bottom"
        effect="solid"
        event="click"
        globalEventOff="click"
        arrowColor="transparent"
        clickable
      >
        <TooltipSearchableContent
          title="Add Tags"
          addButtonText="+ Create new tag"
          isLoading={false}
          onCreate={handleCreateTag}
          onSelect={(tag, selected) => handleToggleTag(tag.id, tag.title, selected)}
          items={allTags?.map((item: TagType) => ({
            title: item.title,
            id: item.id,
            selected: tags?.some((tag: TagType) => tag.id === item.id),
          }))}
        />
      </ReactTooltip>
    </OpportunityContactSidebarSection>
  );
};

export default OpportunityContactSidebarSectionTags;
