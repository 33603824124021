import React, { useCallback, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import { ContentMetricsType, ContentMetricsTypeMap } from 'respona_api/generated/common_pb';

import {
  deleteMetricsValueByIdApi,
  getAllMetricsByCompanyIdApi,
  getAllMetricsViewSettingsByCompanyIdApi,
  loadMetricsValueByIdApi,
  setMetricsValueByIdApi,
  showMetricsByTypeApi,
} from '@api/website.api';

import { fetchBillingCredits } from '@redux/thunks/billings.thunks';

import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';

import { fromNumberAhrefFormatHelper } from '@helpers/transformDataHelpers';

import { SVGIcon } from '@uikit/Icon/Icon';
import Checkbox from '@uikit/Checkbox/Checkbox';
import Loader from '@uikit/Loader/Loader';
import TooltipSearchableContent from '@uikit/TooltipSearchableContent/TooltipSearchableContent';

import ContactsSidebarCRMSectionEditableField from '@components/ContactSidebarCRM/_components/ContactsSidebarCRMSection/_components/ContactsSidebarCRMSectionEditableField/ContactsSidebarCRMSectionEditableField';

import './OpportunityContactSidebarSectionMetrics.scss';
import { DispatchType } from 'src/store';
import OpportunityContactSidebarSection from '@components/CampaignSettings/OpportunityContactSidebar/_components/OpportunityContactSidebarSection/OpportunityContactSidebarSection';
import { OpportunitySidebarProps } from '@ts/opportunity.types';
import {
  composeDomainRatingTitle,
  composeMetricsIcon,
  formatMetrics,
  getTypeInfo,
  IconsMapByType,
  isAllMetricsZero,
} from '@helpers/opportunitiesHelpers';
import Display from '@components/Display/Display';
import {
  PeopleWebsiteMetricsRequest,
  PeopleWebsiteMetricsResponse,
  PeopleWebsiteMetricsViewSettingsResponse
} from 'respona_api/generated/people-website_pb';

function Metrics({
  item,
  onDelete,
  onRefresh,
  isRefreshing,
  onSave,
}: {
  item: PeopleWebsiteMetricsResponse.AsObject;
  onDelete: () => void;
  onRefresh: () => void;
  onSave: (data: PeopleWebsiteMetricsResponse.AsObject) => void;
  isRefreshing?: boolean;
  isOpen?: boolean;
}) {
  const icon = IconsMapByType[item.type] || null;

  const isMoz = item.type === ContentMetricsType.MOZ_METRICS;
  const isSemrush = item.type === ContentMetricsType.SEMRUSH_METRICS;

  const onSaveCallback = (value: string, key: string) => {
    onSave({
      ...item,
      [key]: Number(value),
    });
  };

  return (
    <div className="opportunity-contacts-sidebar-section-metrics__metric-col">
      <div className="opportunity-contacts-sidebar-section-metrics__metric-row">
        <div className="opportunity-contacts-sidebar-section-metrics__metric-row-icon">
          <img src={icon} alt="metrics-logo" />
        </div>

        <div className="opportunity-contacts-sidebar-section-metrics__metric-row-data">
          <div className="opportunity-contacts-sidebar-section-metrics__metric-row-data-label">
            {isSemrush ? 'Authority score' : 'Domain rating'}
          </div>

          <ContactsSidebarCRMSectionEditableField
            initialValue={item?.rating?.toString()}
            onSave={(_, value) => onSaveCallback(value, 'rating')}
            onRefresh={onRefresh}
            isRefreshing={isRefreshing}
            onDelete={onDelete}
            enableCopy={false}
            renderContent={(value) =>
              Number(value) < 0 ? '-' : fromNumberAhrefFormatHelper(Number(value))
            }
          />
        </div>
      </div>

      {!isMoz ? (
        <div className="opportunity-contacts-sidebar-section-metrics__metric-row">
          <div className="opportunity-contacts-sidebar-section-metrics__metric-row-icon">
            <img src={icon} alt="metrics-logo" />
          </div>

          <div className="opportunity-contacts-sidebar-section-metrics__metric-row-data">
            <div className="opportunity-contacts-sidebar-section-metrics__metric-row-data-label">
              Domain Traffic
            </div>

            <ContactsSidebarCRMSectionEditableField
              initialValue={item?.traffic?.toString()}
              onSave={(_, value) => onSaveCallback(value, 'traffic')}
              onRefresh={onRefresh}
              isRefreshing={isRefreshing}
              onDelete={onDelete}
              enableCopy={false}
              renderContent={(value) =>
                Number(value) < 0 ? '-' : fromNumberAhrefFormatHelper(Number(value))
              }
            />
          </div>
        </div>
      ) : (
        <div className="opportunity-contacts-sidebar-section-metrics__metric-row">
          <div className="opportunity-contacts-sidebar-section-metrics__metric-row-icon">
            <img src={icon} alt="metrics-logo" />
          </div>

          <div className="opportunity-contacts-sidebar-section-metrics__metric-row-data">
            <div className="opportunity-contacts-sidebar-section-metrics__metric-row-data-label">
              Spam score
            </div>

            <ContactsSidebarCRMSectionEditableField
              initialValue={item?.spamScore?.toString()}
              onSave={(_, value) => onSaveCallback(value, 'spamScore')}
              onRefresh={onRefresh}
              isRefreshing={isRefreshing}
              onDelete={onDelete}
              enableCopy={false}
              renderContent={(value) =>
                Number(value) < 0 ? '-' : fromNumberAhrefFormatHelper(Number(value))
              }
            />
          </div>
        </div>
      )}
    </div>
  );
}

function OpportunityContactSidebarSectionMetrics({ opportunity, index }: OpportunitySidebarProps) {
  const ref = useRef(null);
  const dispatch = useDispatch<DispatchType>();
  const { website } = opportunity;
  const websiteId = website?.id;
  const queryKey = ['opportunity-contacts-sidebar-people-metrics', websiteId];
  const tooltipRef = useRef(null);
  const queryClient = useQueryClient();
  const workspaceId = useCurrentWorkspaceId();
  const [queryEnabled, setQueryEnabled] = useState<boolean>(true);

  const { data, isFetching } = useQuery<PeopleWebsiteMetricsResponse.AsObject[]>(
    queryKey,
    () => getAllMetricsByCompanyIdApi(websiteId, workspaceId),
    {
      enabled: queryEnabled && !!websiteId,
      refetchOnWindowFocus: false,
      onSettled: (response) => !!response?.length && ref.current.setOpen(true),
    }
  );

  const typeInfo = getTypeInfo(opportunity);

  const {
    data: metricsSettings,
    isFetching: isLoadingMetricsSetting,
    refetch: refetchMetricsSettings,
  } = useQuery<PeopleWebsiteMetricsViewSettingsResponse.AsObject>(
    [...queryKey, 'settings'],
    () => getAllMetricsViewSettingsByCompanyIdApi(websiteId, workspaceId),
    {
      enabled: !!websiteId,
      refetchOnWindowFocus: false,
      staleTime: 15 * (60 * 1000),
    }
  );

  const showMetricsMutation = useMutation<
    PeopleWebsiteMetricsResponse.AsObject,
    unknown,
    { type: ContentMetricsTypeMap[keyof ContentMetricsTypeMap]; enabled: boolean }
  >(({ type, enabled = true }) => showMetricsByTypeApi(type, websiteId, workspaceId, enabled), {
    onSuccess: (res, variables) => {
      queryClient.setQueryData<PeopleWebsiteMetricsResponse.AsObject[]>(queryKey, (prevData) => {
        dispatch(fetchBillingCredits(true));

        if (!Array.isArray(prevData)) {
          return [res];
        }

        if (prevData.findIndex((item) => item.type === variables.type) >= 0) {
          return prevData.map((d) => {
            if (d.type === variables.type) {
              return res;
            }
            return d;
          });
        }
        return [...prevData, res];
      });
    },
  });

  const loadMetricsMutation = useMutation<
    PeopleWebsiteMetricsResponse.AsObject,
    unknown,
    { id: number; type: ContentMetricsTypeMap[keyof ContentMetricsTypeMap] }
  >(({ id, type }) => loadMetricsValueByIdApi(id, type, websiteId, workspaceId), {
    onSuccess: (res, variables) => {
      queryClient.setQueryData<PeopleWebsiteMetricsResponse.AsObject[]>(queryKey, (prevData) => {
        if (!Array.isArray(prevData)) {
          return [res];
        }

        if (prevData.findIndex((item) => item.type === variables.type) >= 0) {
          return prevData.map((d) => {
            if (d.type === variables.type) {
              return res;
            }
            return d;
          });
        }
        return [...prevData, res];
      });
    },
  });

  const setMetricsMutation = useMutation<
    void,
    unknown,
    { data: PeopleWebsiteMetricsRequest.AsObject }
  >(({ data }) => setMetricsValueByIdApi(data), {
    onSuccess: (_, variables) => {
      queryClient.setQueryData<PeopleWebsiteMetricsResponse.AsObject[]>(queryKey, (prevData) => {
        if (!Array.isArray(prevData)) {
          return [variables.data];
        }

        if (prevData.findIndex((item) => item.id === variables.data.id) >= 0) {
          return prevData.map((d) => {
            if (d.id === variables.data.id) {
              return variables.data;
            }
            return d;
          });
        }
        return [...prevData, variables.data];
      });
    },
  });

  const deleteMetricsMutation = useMutation<void, unknown, { id: number }>(
    ({ id }) => deleteMetricsValueByIdApi(id, websiteId),
    {
      onSuccess: (_, variables) => {
        queryClient.setQueryData<PeopleWebsiteMetricsResponse.AsObject[]>(queryKey, (prevData) => {
          return prevData?.slice()?.filter((item) => item.id !== variables.id) || [];
        });
      },
    }
  );

  const onOpenCallback = useCallback(() => {
    setQueryEnabled(true);
  }, [setQueryEnabled]);

  return (
    <OpportunityContactSidebarSection
      ref={ref}
      index={index}
      id="metrics"
      title="Metrics"
      showArrow
      onOpen={onOpenCallback}
      isInitialOpen={false}
      rightComponent={
        websiteId ? (
          <div
            ref={tooltipRef}
            data-for="opportunity-contacts-sidebar-crm-metrics"
            data-tip=""
            className="opportunity-contacts-sidebar-crm__content__add-btn"
            onClick={(e) => {
              e.stopPropagation();

              refetchMetricsSettings().then(() => ReactTooltip.show(tooltipRef.current));
            }}
          >
            {isLoadingMetricsSetting ? (
              <div className="opportunity-contacts-sidebar-section-metrics__refresh-icon--animated">
                <SVGIcon icon="refresh" size={12} color="#221CB6" />
              </div>
            ) : (
              <>
                <SVGIcon icon="plusIcon" size={10} color="#221CB6" />
                Add
              </>
            )}
          </div>
        ) : null
      }
    >
      <div className="opportunity-contacts-sidebar-section-metrics">
        <Loader isLoading={websiteId && (!data || isFetching)}>
          <>
            {data?.length ? (
              data
                .slice()
                .sort((a, b) => b.type - a.type)
                .map((item) => (
                  <Metrics
                    key={item.id}
                    item={item}
                    onDelete={() => deleteMetricsMutation.mutate({ id: item.id })}
                    onRefresh={() => loadMetricsMutation.mutate({ id: item.id, type: item.type })}
                    isRefreshing={
                      loadMetricsMutation.isLoading &&
                      loadMetricsMutation.variables.type === item.type
                    }
                    onSave={(data) =>
                      setMetricsMutation.mutate({
                        data: { ...data, websiteId, workspaceId },
                      })
                    }
                  />
                ))
            ) : (
              <div className="table-content">
                <div className="opportunity-contacts-sidebar-section-metrics__metric-col">
                  <Display isVisible={isAllMetricsZero(typeInfo)}>
                    <div className="opportunity-contacts-sidebar-section-metrics__metric-row">
                      <span>&mdash;</span>
                    </div>
                  </Display>

                  <Display isVisible={!isAllMetricsZero(typeInfo)}>
                    <Display isVisible={typeInfo.listenScore > 0}>
                      <div className="opportunity-contacts-sidebar-section-metrics__metric-row">
                        <div className="opportunity-contacts-sidebar-section-metrics__metric-row-icon">
                          <img
                            src={IconsMapByType[ContentMetricsType.LISTENNOTES_METRICS]}
                            alt="metrics-logo"
                          />
                        </div>
                        <div className="opportunity-contacts-sidebar-section-metrics__metric-row-data">
                          <p className="opportunity-contacts-sidebar-section-metrics__metric-row-data-label">
                            Listen score
                          </p>
                          <div className="contacts-sidebar-crm-section__editable-row">
                            <span>{formatMetrics(typeInfo.listenScore)}</span>
                          </div>
                        </div>
                      </div>
                    </Display>
                    <Display
                      isVisible={
                        typeInfo.globalRank != null &&
                        typeInfo.globalRank.length &&
                        typeInfo.globalRank !== '0%'
                      }
                    >
                      <div className="opportunity-contacts-sidebar-section-metrics__metric-row">
                        <div className="opportunity-contacts-sidebar-section-metrics__metric-row-icon">
                          <img
                            src={IconsMapByType[ContentMetricsType.LISTENNOTES_METRICS]}
                            alt="metrics-logo"
                          />
                        </div>
                        <div className="opportunity-contacts-sidebar-section-metrics__metric-row-data">
                          <p className="opportunity-contacts-sidebar-section-metrics__metric-row-data-label">
                            Podcast rank
                          </p>
                          <div className="contacts-sidebar-crm-section__editable-row">
                            <span>{formatMetrics(typeInfo.globalRank)}</span>
                          </div>
                        </div>
                      </div>
                    </Display>
                    <Display isVisible={typeInfo.domainRating > 0}>
                      <div className="opportunity-contacts-sidebar-section-metrics__metric-row">
                        <div className="opportunity-contacts-sidebar-section-metrics__metric-row-icon">
                          <img src={composeMetricsIcon(opportunity)} alt="metrics-logo" />
                        </div>
                        <div className="opportunity-contacts-sidebar-section-metrics__metric-row-data">
                          <p className="opportunity-contacts-sidebar-section-metrics__metric-row-data-label">
                            {composeDomainRatingTitle(opportunity)}
                          </p>
                          <div className="contacts-sidebar-crm-section__editable-row">
                            <span>{formatMetrics(typeInfo.domainRating)}</span>
                          </div>
                        </div>
                      </div>
                    </Display>
                    <Display isVisible={typeInfo.domainAuthority > 0}>
                      <div className="opportunity-contacts-sidebar-section-metrics__metric-row">
                        <div className="opportunity-contacts-sidebar-section-metrics__metric-row-icon">
                          <img src={composeMetricsIcon(opportunity)} alt="metrics-logo" />
                        </div>
                        <div className="opportunity-contacts-sidebar-section-metrics__metric-row-data">
                          <p className="opportunity-contacts-sidebar-section-metrics__metric-row-data-label">
                            Domain authority
                          </p>
                          <div className="contacts-sidebar-crm-section__editable-row">
                            <span>{formatMetrics(typeInfo.domainAuthority)}</span>
                          </div>
                        </div>
                      </div>
                    </Display>
                    <Display isVisible={typeInfo.pageAuthority > 0}>
                      <div className="opportunity-contacts-sidebar-section-metrics__metric-row">
                        <div className="opportunity-contacts-sidebar-section-metrics__metric-row-icon">
                          <img src={composeMetricsIcon(opportunity)} alt="metrics-logo" />
                        </div>
                        <div className="opportunity-contacts-sidebar-section-metrics__metric-row-data">
                          <p className="opportunity-contacts-sidebar-section-metrics__metric-row-data-label">
                            Page authority
                          </p>
                          <div className="contacts-sidebar-crm-section__editable-row">
                            <span>{formatMetrics(typeInfo.pageAuthority)}</span>
                          </div>
                        </div>
                      </div>
                    </Display>
                    <Display isVisible={typeInfo.domainTraffic > 0}>
                      <div className="opportunity-contacts-sidebar-section-metrics__metric-row">
                        <div className="opportunity-contacts-sidebar-section-metrics__metric-row-icon">
                          <img src={composeMetricsIcon(opportunity)} alt="metrics-logo" />
                        </div>
                        <div className="opportunity-contacts-sidebar-section-metrics__metric-row-data">
                          <p className="opportunity-contacts-sidebar-section-metrics__metric-row-data-label">
                            Domain traffic
                          </p>
                          <div className="contacts-sidebar-crm-section__editable-row">
                            <span>{formatMetrics(typeInfo.domainTraffic)}</span>
                          </div>
                        </div>
                      </div>
                    </Display>
                    <Display isVisible={typeInfo.domainTrafficKeywordsNumber > 0}>
                      <div className="opportunity-contacts-sidebar-section-metrics__metric-row">
                        <div className="opportunity-contacts-sidebar-section-metrics__metric-row-icon">
                          <img src={composeMetricsIcon(opportunity)} alt="metrics-logo" />
                        </div>
                        <div className="opportunity-contacts-sidebar-section-metrics__metric-row-data">
                          <p className="opportunity-contacts-sidebar-section-metrics__metric-row-data-label">
                            Organic keywords
                          </p>
                          <div className="contacts-sidebar-crm-section__editable-row">
                            <span>{formatMetrics(typeInfo.domainTrafficKeywordsNumber)}</span>
                          </div>
                        </div>
                      </div>
                    </Display>
                    <Display isVisible={typeInfo.domainAuthorityScore > 0}>
                      <div className="opportunity-contacts-sidebar-section-metrics__metric-row">
                        <div className="opportunity-contacts-sidebar-section-metrics__metric-row-icon">
                          <img src={composeMetricsIcon(opportunity)} alt="metrics-logo" />
                        </div>
                        <div className="opportunity-contacts-sidebar-section-metrics__metric-row-data">
                          <p className="opportunity-contacts-sidebar-section-metrics__metric-row-data-label">
                            Authority score
                          </p>
                          <div className="contacts-sidebar-crm-section__editable-row">
                            <span>{formatMetrics(typeInfo.domainAuthorityScore)}</span>
                          </div>
                        </div>
                      </div>
                    </Display>
                    <Display isVisible={typeInfo.domainSpamScore > 0}>
                      <div className="opportunity-contacts-sidebar-section-metrics__metric-row">
                        <div className="opportunity-contacts-sidebar-section-metrics__metric-row-icon">
                          <img src={composeMetricsIcon(opportunity)} alt="metrics-logo" />
                        </div>
                        <div className="opportunity-contacts-sidebar-section-metrics__metric-row-data">
                          <p className="opportunity-contacts-sidebar-section-metrics__metric-row-data-label">
                            Domain spam score
                          </p>
                          <div className="contacts-sidebar-crm-section__editable-row">
                            <span>{formatMetrics(typeInfo.domainSpamScore)}</span>
                          </div>
                        </div>
                      </div>
                    </Display>
                  </Display>
                </div>
              </div>
            )}
          </>
        </Loader>
      </div>

      <ReactTooltip
        id="opportunity-contacts-sidebar-crm-metrics"
        className="react-tooltip opportunity-contacts-sidebar-section-metrics__tooltip"
        place="left"
        event="none"
        effect="float"
        globalEventOff="click"
        bodyMode={false}
        arrowColor="transparent"
        clickable
      >
        <TooltipSearchableContent
          title="Add Metrics"
          addButtonText="+ Connect other sources"
          customCreateAction={() => window.open('/settings/integrations', '_self')}
          searchDisabled
        >
          <div className="tooltip-searchable-content-items">
            <div className="opportunity-contacts-sidebar-section-metrics__metric-options-row">
              <div className="opportunity-contacts-sidebar-section-metrics__metric-options-row-icon">
                <img src={IconsMapByType[ContentMetricsType.MOZ_METRICS]} alt="metrics-logo" />
              </div>
              <span>Moz metrics</span>

              <Checkbox
                onChange={() =>
                  showMetricsMutation.mutate({
                    type: ContentMetricsType.MOZ_METRICS,
                    enabled: false,
                  })
                }
                value={data?.some((item) => item.type === ContentMetricsType.MOZ_METRICS)}
                // disabled={data?.some((item) => item.type === ContentMetricsType.MOZ_METRICS)}
                isLoading={
                  showMetricsMutation.isLoading &&
                  showMetricsMutation.variables.type === ContentMetricsType.MOZ_METRICS
                }
              />
            </div>

            {metricsSettings?.ahrefEnabled && (
              <div className="opportunity-contacts-sidebar-section-metrics__metric-options-row">
                <div className="opportunity-contacts-sidebar-section-metrics__metric-options-row-icon">
                  <img src={IconsMapByType[ContentMetricsType.AHREFS_METRICS]} alt="metrics-logo" />
                </div>
                <span>Ahrefs metrics</span>

                <Checkbox
                  onChange={() =>
                    showMetricsMutation.mutate({
                      type: ContentMetricsType.AHREFS_METRICS,
                      enabled: false,
                    })
                  }
                  value={data?.some((item) => item.type === ContentMetricsType.AHREFS_METRICS)}
                  isLoading={
                    showMetricsMutation.isLoading &&
                    showMetricsMutation.variables.type === ContentMetricsType.AHREFS_METRICS
                  }
                />
                {/* <div */}
                {/*  className="opportunity-contacts-sidebar-section-metrics__metric-options-row-refresh-icon" */}
                {/*  onClick={() => */}
                {/*    showMetricsMutation.mutate({ */}
                {/*      type: ContentMetricsType.AHREF_METRICS, */}
                {/*      enabled: false, */}
                {/*    }) */}
                {/*  } */}
                {/* > */}
                {/*  <SVGIcon icon="refresh" size={12} /> */}
                {/* </div> */}
              </div>
            )}

            {metricsSettings?.semrushEnabled && (
              <div className="opportunity-contacts-sidebar-section-metrics__metric-options-row">
                <div className="opportunity-contacts-sidebar-section-metrics__metric-options-row-icon">
                  <img
                    src={IconsMapByType[ContentMetricsType.SEMRUSH_METRICS]}
                    alt="metrics-logo"
                  />
                </div>
                <span>Semrush metrics</span>

                <Checkbox
                  onChange={() =>
                    showMetricsMutation.mutate({
                      type: ContentMetricsType.SEMRUSH_METRICS,
                      enabled: false,
                    })
                  }
                  value={data?.some((item) => item.type === ContentMetricsType.SEMRUSH_METRICS)}
                  // disabled={data?.some((item) => item.type === ContentMetricsType.SEMRUSH_METRICS)}
                  isLoading={
                    showMetricsMutation.isLoading &&
                    showMetricsMutation.variables.type === ContentMetricsType.SEMRUSH_METRICS
                  }
                />
              </div>
            )}
          </div>
        </TooltipSearchableContent>
      </ReactTooltip>
    </OpportunityContactSidebarSection>
  );
}

export default OpportunityContactSidebarSectionMetrics;
