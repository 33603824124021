import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { DispatchType } from 'src/store';
import { SelectOptionType, TagType } from '@ts/common.types';

import { addNotification } from '@redux/actions/notifications.actions';

import { SVGIcon } from '@uikit/Icon/Icon';

import './OpportunityContactSidebarSectionCustomVariables.scss';
import OpportunityContactSidebarSection from '@components/CampaignSettings/OpportunityContactSidebar/_components/OpportunityContactSidebarSection/OpportunityContactSidebarSection';
import { OpportunitySidebarProps } from '@ts/opportunity.types';
import Loader from '@uikit/Loader/Loader';
import {
  CustomVariablePlaceholderType,
  CustomVariableSelectItemValueType,
  CustomVariableSelectType,
  CustomVariableValueType,
} from '@ts/customVariales.types';
import { CustomVariableType, CustomVariableValueRelatedTo } from 'respona_api/generated/common_pb';
import { fromNumberAhrefFormatHelper } from '@helpers/transformDataHelpers';
import Checkbox from '@uikit/Checkbox/Checkbox';
import HeaderDateFilter from '@uikit/HeaderDateFilter/HeaderDateFilter';
import Select from '@uikit/Select/Select';
import CustomAvatar from '@components/CustomAvatar/CustonAvatar';
import CustomOptionAvatar from '@components/CustomOptionAvatar/CustomOptionAvatar';
import Rating from 'react-rating';
import { transformCustomVariableOptionsToOptions } from '@mappers/optionTransformers';
import MultiValueContainer from '@components/CustomFieldsSidebar/_components/MultiValueContainer';
import useCustomVariables from '@hooks/useCustomVariables';
import useWorkspaceMembers from '@hooks/useWorkspaceMembers';
import ContactsSidebarCRMSectionEditableField from '@components/ContactSidebarCRM/_components/ContactsSidebarCRMSection/_components/ContactsSidebarCRMSectionEditableField/ContactsSidebarCRMSectionEditableField';
import { SelectCustomVariableSettings } from 'respona_api/generated/people_pb';
import {
  createOrUpdateCustomVariableValueApi,
  getCustomVariableValuesApi,
} from '@api/customVariables.api';

function OpportunityContactSidebarSectionCustomVariables({
  opportunity,
  index,
}: OpportunitySidebarProps) {
  const { website } = opportunity;
  const personId = undefined;
  const { id: websiteId } = website;
  const ref = useRef(null);
  const dispatch = useDispatch<DispatchType>();
  const [queryEnabled, setQueryEnabled] = useState<boolean>(true);
  const queryClient = useQueryClient();
  const [isOpen, setOpen] = useState(false);
  const queryKey = ['contacts-sidebar-variables', personId, websiteId];
  const { customVariables, isCustomVariablesLoading } = useCustomVariables();
  const { members: workspaceMembers } = useWorkspaceMembers();

  const relatedIds = useMemo(() => [personId, websiteId].filter(Boolean), [personId, websiteId]);

  const customVariablesByType = useMemo(
    () =>
      customVariables?.filter((it) => {
        if (it.id < 0 || it?.isDefault === true || it.type === CustomVariableType.OPEN_AI) return false;
        if (websiteId) return it.relatedTo === CustomVariableValueRelatedTo.WEBSITE;
        if (personId) return it.relatedTo === CustomVariableValueRelatedTo.PERSON;
        return false;
      }) || [],
    [customVariables, websiteId, personId]
  );

  const { data: valuesData, isLoading: isValuesLoading } = useQuery<CustomVariableValueType[]>(
    queryKey,
    () =>
      getCustomVariableValuesApi(
        customVariablesByType.map((it) => it.name),
        relatedIds
      ),
    {
      enabled: queryEnabled && customVariablesByType.length > 0 && relatedIds.length > 0,
      refetchOnWindowFocus: false,
      onSettled: (response) => response?.length && ref.current?.setOpen(true),
      staleTime: 5 * 60 * 1000,
    }
  );

  const assignList = useMemo(() => {
    const sortedMembers = workspaceMembers
      .sort((a, b) => Number(b.isMe) - Number(a.isMe))
      .map((member) => ({
        value: member.userId,
        label: member.isMe ? 'Assign to me' : member.fullName,
        logoUrl: member.logoUrl,
      }));

    return [
      {
        value: -1,
        label: 'Unassigned',
        logoUrl: '',
      },
      ...sortedMembers,
    ];
  }, [workspaceMembers]);

  const renderEditableField = (
    key: string,
    initialValue: string,
    onSave: (newValue: string) => void,
    renderContent: (value: string) => string
  ) => (
    <>
      <div className="contacts-sidebar-section-variables-variable-info-label w-100">{key}</div>
      <ContactsSidebarCRMSectionEditableField
        key={key}
        initialValue={initialValue}
        onSave={(_, newValue) => onSave(newValue)}
        renderContent={renderContent}
        extraRowClass="padding-top-none"
      />
    </>
  );

  const handleSaveVariable = useCallback(
    (newVariable: CustomVariableValueType) => {
      createOrUpdateCustomVariableValueApi(newVariable).then(() => {
        queryClient.setQueryData(queryKey, (data: CustomVariableValueType[]) => {
          if (!data) {
            return [newVariable];
          }
          const existingIndex = data.findIndex(
            (item) => item.variableId === newVariable.variableId
          );
          if (existingIndex === -1) {
            return [...data, newVariable];
          }
          return data.map((item, ind) => (ind === existingIndex ? newVariable : item));
        });
      });
    },
    [queryClient, queryKey]
  );

  const handleSaveStringVariable = useCallback(
    (newInput: string, variable: CustomVariablePlaceholderType, value: CustomVariableValueType) => {
      const newVariable = value
        ? { ...value, stringValue: newInput }
        : ({
            variableId: variable.id,
            variableName: variable.name,
            type: variable.type,
            relatedTo: variable.relatedTo,
            relatedId: relatedIds[0],
            stringValue: newInput,
          } as CustomVariableValueType);
      handleSaveVariable(newVariable);
    },
    [handleSaveVariable, relatedIds]
  );

  const handleSaveSelectVariable = (
    newInput: { label: string; value: string }[],
    variable: CustomVariablePlaceholderType,
    value: CustomVariableValueType
  ) => {
    const select = {
      valuesList: newInput.map((it) => {
        return { uid: it.value, value: it.label } as CustomVariableSelectItemValueType;
      }),
    } as CustomVariableSelectType;
    const newVariable = value
      ? ({ ...value, selectValue: select } as CustomVariableValueType)
      : ({
          variableId: variable.id,
          variableName: variable.name,
          type: variable.type,
          relatedTo: variable.relatedTo,
          relatedId: relatedIds[0],
          stringValue: null,
          selectValue: select,
        } as CustomVariableValueType);
    handleSaveVariable(newVariable);
  };

  const composeOptionsFromSettings = (settings: SelectCustomVariableSettings.AsObject) => {
    return settings.itemsList
      .sort((a, b) => (a.order <= b.order ? -1 : 1))
      .map((it) => {
        return { label: it.name, value: it.uid, color: it.color };
      });
  };

  function enrichOptionsWithColor(
    options: SelectOptionType[],
    select: SelectCustomVariableSettings.AsObject
  ) {
    if (options?.length > 0) {
      return options.map((opt) => {
        const object = select.itemsList.find((it) => it.uid === opt.value);
        return { ...opt, color: object?.color };
      });
    }

    return [];
  }

  const renderVariable = useCallback(
    (item: CustomVariablePlaceholderType) => {
      const variableValue = valuesData?.find((it) => it.variableId === item.id);

      switch (item.type) {
        case CustomVariableType.TEXT:
          return renderEditableField(
            item.title,
            variableValue?.stringValue || '',
            (newValue) => handleSaveStringVariable(newValue, item, variableValue),
            (value) => (value?.length ? value : '-')
          );

        case CustomVariableType.INT:
          return renderEditableField(
            item.title,
            variableValue?.stringValue || '',
            (newValue) => handleSaveStringVariable(newValue, item, variableValue),
            (value) => (Number.isNaN(value) ? '-' : fromNumberAhrefFormatHelper(Number(value)))
          );

        case CustomVariableType.URL:
          return renderEditableField(
            item.title,
            variableValue?.stringValue || '',
            (newValue) => {
              const urlPattern = /^(https?:\/\/)?([\w.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
              if (urlPattern.test(newValue)) {
                handleSaveStringVariable(newValue, item, variableValue);
              } else {
                dispatch(addNotification({ title: 'Invalid URL format', type: 'warning' }));
              }
            },
            (value) => (value?.length ? value : '-')
          );

        case CustomVariableType.CHECKBOX:
          return (
            <div
              key={item.id}
              className="contacts-sidebar-section-variables-variable-info-label w-100"
            >
              <div>{item.title}</div>
              <Checkbox
                value={variableValue?.stringValue === 'true'}
                onChange={({ target: { checked } }) =>
                  handleSaveStringVariable(checked.toString(), item, variableValue)
                }
              />
            </div>
          );

        case CustomVariableType.DATE_AND_TIME:
          return (
            <div
              key={item.id}
              className="contacts-sidebar-section-variables-variable-info-label w-100"
            >
              <div className="contacts-sidebar-section-variables-variable-info-label w-100">
                {item.title}
              </div>
              <HeaderDateFilter
                value={{
                  startDate: Number.isNaN(variableValue?.stringValue)
                    ? null
                    : Number(variableValue?.stringValue),
                }}
                onChangeDatarange={(value) =>
                  handleSaveStringVariable(
                    value.startDate.getTime().toString(),
                    item,
                    variableValue
                  )
                }
                isRangePicker={false}
                id={item.id.toString()}
                skipDayCheck
              />
            </div>
          );

        case CustomVariableType.TEAM_MEMBER: {
          const composeAssignee = (userIdValue: string) => {
            const assignedUserId = Number(userIdValue);
            const user = assignList.find((it) => it.value === assignedUserId);
            if (user) {
              return {
                value: assignedUserId,
                label:
                  user.label === 'Assign to me'
                    ? workspaceMembers.find(({ userId }) => userId === user.value).fullName
                    : user.label,
                logoUrl: user.logoUrl,
              };
            }
            if (assignedUserId) {
              return {
                value: assignedUserId,
                label: 'Removed User',
                logoUrl: '',
              };
            }
            return {
              value: -1,
              label: 'Unassigned',
              logoUrl: '',
            };
          };
          return (
            <div key={item.id} style={{ width: '100%' }}>
              <div className="contacts-sidebar-section-variables-variable-info-label w-100">
                {item.title}
              </div>
              <div className="contacts-sidebar-section-variables-variable-info-select">
                <Select
                  openMenuOnFocus
                  classNamePrefix="assign"
                  placeholder="Assign user"
                  onChange={(value) =>
                    handleSaveStringVariable(value.value.toString(), item, variableValue)
                  }
                  value={composeAssignee(variableValue?.stringValue)}
                  options={assignList}
                  additionalComponents={{ Control: CustomAvatar, Option: CustomOptionAvatar }}
                  additionalStyles={{
                    control: (provided) => ({
                      ...provided,
                      minHeight: '40px',
                      borderRadius: '8px',
                      paddingLeft: '8px',
                    }),
                    option: () => ({
                      display: 'flex',
                      gridColumnGap: '8px',
                      alignItems: 'center',
                      width: '100%',
                      color: 'white',
                      padding: '8px 12px',
                      '&:hover, &:focus': {
                        backgroundColor: '#181818',
                      },
                    }),
                  }}
                />
              </div>
            </div>
          );
        }

        case CustomVariableType.RATING: {
          return (
            <div
              key={item.id}
              className="contacts-sidebar-section-variables-variable-info-label w-100"
            >
              <div className="contacts-sidebar-section-variables-variable-info-label w-100">
                {item.title}
              </div>
              <Rating
                emptySymbol={<SVGIcon icon="star" color="#BDBDBD" />}
                fullSymbol={<SVGIcon icon="fullStar" />}
                initialRating={
                  Number.isNaN(variableValue?.stringValue) ? 0 : Number(variableValue?.stringValue)
                }
                onChange={(value) =>
                  handleSaveStringVariable(value.toString(), item, variableValue)
                }
              />
            </div>
          );
        }

        case CustomVariableType.SELECT: {
          const values = transformCustomVariableOptionsToOptions(
            variableValue?.selectValue?.valuesList
          );
          const coloredValue = enrichOptionsWithColor(values, item.select);
          const initialValue = coloredValue?.length ? values[0] : null;

          return (
            <div
              key={item.id}
              className="contacts-sidebar-section-variables-variable-info-label w-100"
            >
              <div className="contacts-sidebar-section-variables-variable-info-label w-100">
                {item.title}
              </div>
              <div className="contacts-sidebar-section-variables-variable-info-select">
                <Select
                  options={composeOptionsFromSettings(item.select)}
                  value={initialValue}
                  onChange={(opt) => handleSaveSelectVariable([opt], item, variableValue)}
                />
              </div>
            </div>
          );
        }

        case CustomVariableType.MULTI_SELECT: {
          const values = transformCustomVariableOptionsToOptions(
            variableValue?.selectValue?.valuesList
          );
          const coloredValues = enrichOptionsWithColor(values, item.select);

          return (
            <div
              key={item.id}
              className="contacts-sidebar-section-variables-variable-info-label w-100"
            >
              <div className="contacts-sidebar-section-variables-variable-info-label w-100">
                {item.title}
              </div>
              <div className="contacts-sidebar-section-variables-variable-info-select">
                <Select
                  isMulti
                  isClearable={false}
                  visibleMultiCount={3}
                  options={composeOptionsFromSettings(item.select)}
                  value={coloredValues}
                  onChange={(opt) => handleSaveSelectVariable(opt, item, variableValue)}
                  additionalComponents={{ MultiValueContainer }}
                />
              </div>
            </div>
          );
        }

        default:
          return <div />;
      }
    },
    [
      valuesData,
      handleSaveStringVariable,
      dispatch,
      assignList,
      composeOptionsFromSettings,
      workspaceMembers,
    ]
  );

  return (
    <OpportunityContactSidebarSection
      ref={ref}
      index={index}
      id="customFields"
      title="Custom fields"
      showArrow={customVariables?.length > 0}
      onOpen={() => setOpen(!isOpen)}
      isInitialOpen={customVariables?.length > 0}
      rightComponent={
        website == null || website.id <= 0 ? null : (
          <div
            className="opportunity-contacts-sidebar-crm__content__add-btn"
            data-for="opportunity-contacts-sidebar-crm-tags"
            data-tip=""
          >
            <SVGIcon icon="plusIcon" size={10} color="#221CB6" />
            Add
          </div>
        )
      }
    >
      <Loader isLoading={isCustomVariablesLoading}>
        <div className="opportunity-contacts-sidebar-section-custom-variables">
          {customVariablesByType.length ? (
            customVariablesByType.map(renderVariable)
          ) : (
            <div className="contacts-sidebar-section-variables--empty">Empty</div>
          )}
        </div>
      </Loader>
    </OpportunityContactSidebarSection>
  );
}

export default OpportunityContactSidebarSectionCustomVariables;
