import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { QueryKey, useQuery } from 'react-query';
import { PeopleShortResponse } from 'respona_api/generated/people_pb';

import { PersonBaseType, RelationshipStatusType } from '@ts/people.types';
import { WebsiteType } from '@ts/company.types';

import {
  getPeopleByCompanyIdApi,
  updatePersonApi,
  updatePersonStatus,
  updateSocialsApi,
} from '@api/people.api';

import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';

import Loader from '@uikit/Loader/Loader';
import RightInfoSidebar from '@uikit/RightInfoSidebar/RightInfoSidebar';

import ContactSidebarCRMHeader from '@components/ContactSidebarCRM/_components/ContactSidebarCRMHeader/ContactSidebarCRMHeader';
import ContactsSidebarCRMSectionMetrics from '@components/ContactSidebarCRM/_components/ContactsSidebarCRMSectionMetrics/ContactsSidebarCRMSectionMetrics';
import ContactsSidebarCRMSectionNotes from '@components/ContactSidebarCRM/_components/ContactsSidebarCRMSectionNotes/ContactsSidebarCRMSectionNotes';
import ContactsSidebarCRMSectionOpportunities from '@components/ContactSidebarCRM/_components/ContactsSidebarCRMSectionOpportunities/ContactsSidebarCRMSectionOpportunities';
import ContactsSidebarCRMSectionTags from '@components/ContactSidebarCRM/_components/ContactsSidebarCRMSectionTags/ContactsSidebarCRMSectionTags';
import ContactsSidebarCRMSectionEmails from '@components/ContactSidebarCRM/_components/ContactsSidebarCRMSectionEmails/ContactsSidebarCRMSectionEmails';
import ContactsSidebarCRMSectionPersonal, {
  personStatusOptions,
} from '@components/ContactSidebarCRM/_components/ContactsSidebarCRMSectionPersonal/ContactsSidebarCRMSectionPersonal';

import './ContactSidebarCRM.scss';
import { getDateShort } from '@utils/date';
import ContactsSidebarCRMSectionEditableField from '@components/ContactSidebarCRM/_components/ContactsSidebarCRMSection/_components/ContactsSidebarCRMSectionEditableField/ContactsSidebarCRMSectionEditableField';
import { createOrUpdateCompanyApi } from '@api/website.api';
import { format } from 'date-fns';
import { relationshipStatusInfoMap } from '@components/Relationships/PeopleTable/_components/RelationshipStatus';
import ContactsSidebarCRMSectionCampaigns from '@components/ContactSidebarCRM/_components/ContactsSidebarCRMSectionCampaigns/ContactsSidebarCRMSectionCampaigns';
import { SVGIcon, SvgIconType } from '@uikit/Icon/Icon';
import { socialLinkTypeToIconMapper } from '@uikit/SocialLinks/SocialLinks';
import ContactsSidebarCRMSectionSelectionField from '@components/ContactSidebarCRM/_components/ContactsSidebarCRMSection/_components/ContactsSidebarCRMSectionSelectionField/ContactsSidebarCRMSectionSelectionField';
import { PeopleRelationshipStatus } from 'respona_api/generated/common_pb';
import ContactsSidebarCRMSectionVariables from '@components/ContactSidebarCRM/_components/ContactsSidebarCRMSectionVariables/ContactsSidebarCRMSectionVariables';

declare const IS_DEV: boolean;

export enum contactSidebarSize {
  SMALL = 'SMALL',
  NORMAL = 'NORMAL',
}

type Props = {
  isOpen: boolean;
  onClose?: () => void;
  person?: PersonBaseType;
  website?: WebsiteType;
  queryKey?: QueryKey;
  size?: contactSidebarSize;
  withCompanyPeople?: boolean;
  companyPeopleFilterEmails?: string[];
  companyPeopleFilterIDs?: number[];
  onUpdate?: (person?: PersonBaseType, website?: WebsiteType) => void;
  onRightComponentClick?: (sectionName: string, targetId: number) => void;
  isWebsite: boolean;
};

export const SECTION_TYPE = {
  CONTACTS: 'contacts',
  TAGS: 'tags',
  EMAILS: 'emails',
  CAMPAIGNS: 'campaigns',
  NOTES: 'notes',
  METRICS: 'metrics',
  VARIABLES: 'variables',
};

// TODO add check on IS_STANDALONE_MODE
if (true) {
  // @ts-ignore
  SECTION_TYPE.OPPORTUNITIES = 'opportunities';
}

const sectionsMap = {
  [SECTION_TYPE.CONTACTS]: ContactsSidebarCRMSectionPersonal,
  [SECTION_TYPE.TAGS]: ContactsSidebarCRMSectionTags,
  [SECTION_TYPE.EMAILS]: ContactsSidebarCRMSectionEmails,
  [SECTION_TYPE.CAMPAIGNS]: ContactsSidebarCRMSectionCampaigns,
  [SECTION_TYPE.NOTES]: ContactsSidebarCRMSectionNotes,
  [SECTION_TYPE.METRICS]: ContactsSidebarCRMSectionMetrics,
  [SECTION_TYPE.VARIABLES]: ContactsSidebarCRMSectionVariables,
};

// TODO add check on IS_STANDALONE_MODE
if (true) {
  // @ts-ignore
  sectionsMap[SECTION_TYPE.OPPORTUNITIES] = ContactsSidebarCRMSectionOpportunities;
}

function ContactSidebarCRMContent({
  person,
  website,
  queryKey,
  onUpdate,
  onRightComponentClick,
}: Partial<Props>) {
  const location = useLocation();
  const [sections, setSections] = useState<string[]>([]);
  const currentWorkspaceId = useCurrentWorkspaceId();

  const handleDragDrop = useCallback((res: any) => {
    const { source, destination } = res;

    if (!destination || destination.index === source.index) {
      return;
    }

    setSections((state) => {
      const newState = [...state];
      newState.splice(source.index, 1);
      newState.splice(destination.index, 0, state[source.index]);

      localStorage.setItem('contactsSectionsOrder', JSON.stringify(newState));
      return newState;
    });
  }, []);

  useEffect(() => {
    const sectionOrder = localStorage.getItem('contactsSectionsOrder');
    try {
      const sectionOrderParsed = JSON.parse(sectionOrder);
      if (sectionOrderParsed && sectionOrderParsed.length === Object.values(SECTION_TYPE).length) {
        setSections(sectionOrderParsed);
      } else {
        localStorage.removeItem('contactsSectionsOrder');
        setSections(Object.values(SECTION_TYPE));
      }
    } catch {
      localStorage.removeItem('contactsSectionsOrder');
      setSections(Object.values(SECTION_TYPE));
    }
  }, []);

  const handleSaveEmail = useCallback(
    async (initialValue: string, updatedTitle: string) => {
      if (person?.id) {
        const updatedPerson = {
          ...person,
          emailsList: person.emailsList.map((email) =>
            email.email === initialValue ? { ...email, email: updatedTitle } : email
          ),
        };
        const personResponse = await updatePersonApi(updatedPerson);
        onUpdate?.(personResponse);
      }
    },
    [person, onUpdate]
  );

  const handleSavePersonName = useCallback(
    async (initialValue: string, updatedValue: string) => {
      if (person?.id) {
        const updatedPerson = { ...person, name: updatedValue };
        const personResponse = await updatePersonApi(updatedPerson);
        onUpdate?.(personResponse);
      }
    },
    [person, onUpdate]
  );

  const handleSaveCompanyName = useCallback(
    async (initialValue: string, updatedValue: string) => {
      const id = website?.id ?? person?.website.id;
      const companyResponse = await createOrUpdateCompanyApi({
        id: id > 0 ? id : undefined,
        title: updatedValue,
        domain: website?.domain || person?.website?.domain || updatedValue,
        workspaceId: website?.workspaceId || person?.workspaceId || currentWorkspaceId,
      });

      if (person?.id) {
        const updatedPerson = {
          ...person,
          company: {
            domain: companyResponse.domain,
            name: companyResponse.title,
            logo: companyResponse.logoUrl,
          },
        };
        const personResponse = await updatePersonApi(updatedPerson);
        onUpdate?.(personResponse, companyResponse);
      } else {
        onUpdate?.(person, companyResponse);
      }
    },
    [website, person, currentWorkspaceId, onUpdate]
  );

  const handleChangeStatus = useCallback(
    async (initStatus: RelationshipStatusType, newStatus: RelationshipStatusType) => {
      if (person?.id) {
        await updatePersonStatus(person.id, newStatus);
        const updatedPerson = { ...person, status: newStatus };
        onUpdate?.(
          updatedPerson,
          website
            ? {
                ...website,
                contactsList: website.contactsList.map((it) =>
                  it.id !== person.id ? it : { ...it, status: newStatus }
                ),
              }
            : undefined
        );
      }
    },
    [person, website, onUpdate]
  );

  const handleChangeSocLink = useCallback(
    async (index: number, initValue: string, newValue: string) => {
      if (person) {
        const newSocLinks = person.socialLinksList.map((socLink, indexItem) =>
          indexItem === index ? { ...socLink, url: newValue } : socLink
        );
        await updateSocialsApi(person.id, initValue, newValue);
        onUpdate?.({ ...person, socialLinksList: newSocLinks });
      }
    },
    [person, onUpdate]
  );

  if (!person && !website) {
    return null;
  }

  const createdAt = website?.createdAt || person?.createdAt;
  const status: RelationshipStatusType =
    website?.status ?? person?.status ?? PeopleRelationshipStatus.RELATIONSHIP_STATUS_NOT_CONTACTED;

  return (
    <div className="contact-sidebar-crm__content">
      <div className="contact-sidebar-crm__content-header">
        {website ? (
          <>
            <div className="contact-sidebar-crm__content-personal-info-label">Website name</div>
            <div className="contact-sidebar-crm-header-name">
              <ContactsSidebarCRMSectionEditableField
                initialValue={website.title}
                onSave={handleSaveCompanyName}
                renderContent={(value) => value || '-'}
              />
            </div>
            <div className="contact-sidebar-crm__content-personal-info-label">
              Relationship status
            </div>
            <div className="contact-sidebar-crm__content-personal-info-data-row">
              {relationshipStatusInfoMap[status].title}
            </div>
          </>
        ) : (
          <>
            <div className="contact-sidebar-crm__content-personal-info-label">Contact name</div>
            <div className="contact-sidebar-crm-header-name">
              <ContactsSidebarCRMSectionEditableField
                initialValue={person?.name}
                onSave={handleSavePersonName}
                renderContent={(value) => value || '-'}
              />
            </div>
            <div className="contact-sidebar-crm__content-personal-info-label">Email(s)</div>
            {person?.emailsList?.map(({ email }) => (
              <div key={email} className="contact-sidebar-crm-header-name">
                <ContactsSidebarCRMSectionEditableField
                  initialValue={email}
                  onSave={handleSaveEmail}
                  renderContent={(value) => value || '-'}
                />
              </div>
            ))}
            <div className="contact-sidebar-crm__content-personal-info-label">
              Relationship status
            </div>
            <ContactsSidebarCRMSectionSelectionField
              initialStatus={status}
              statusOptions={personStatusOptions}
              onChange={handleChangeStatus}
            />
          </>
        )}
        <div className="contact-sidebar-crm__content-personal-info-label">Created on</div>
        <div className="contact-sidebar-crm__content-personal-info-data-row">
          {createdAt
            ? `${getDateShort(new Date(createdAt), true, true)} at ${format(
                new Date(createdAt),
                'hh:mm aaa'
              )}`
            : ''}
        </div>
        {website ? null : (
          <>
            <div className="contact-sidebar-crm__content-personal-info-label">Social links</div>
            <div className="contact-sidebar-crm__content-personal-info-data-row">
              {person?.socialLinksList?.map((socLink, index) => (
                <div
                  key={socLink.url}
                  className="contact-sidebar-crm__content-personal-info-data-row contact-sidebar-crm__content-personal-info-data-row-social-link social-link-info"
                >
                  <SVGIcon icon={socialLinkTypeToIconMapper[socLink.type] as SvgIconType} />
                  <ContactsSidebarCRMSectionEditableField
                    extraClass="social-link-info_editor"
                    initialValue={socLink.url}
                    onSave={(initialValue, newValue) =>
                      handleChangeSocLink(index, socLink.url, newValue)
                    }
                    renderContent={(value) => value || '-'}
                  />
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      <DragDropContext onDragEnd={handleDragDrop}>
        <Droppable droppableId="contact-sidebar" direction="vertical">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {sections.map((key, index) => {
                if (
                  key === SECTION_TYPE.CONTACTS &&
                  location.pathname === '/relationships/people'
                ) {
                  return null;
                }

                const Section = sectionsMap[key];
                if (!Section) {
                  console.error(`Invalid section type: ${key}`);
                  return null;
                }
                return (
                  <Section
                    person={person}
                    website={website}
                    index={index}
                    key={key}
                    onUpdate={onUpdate}
                    queryKey={queryKey}
                    onRightComponentClick={onRightComponentClick}
                  />
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

function ContactSidebarCRM({
  isOpen,
  onClose,
  person,
  website,
  queryKey,
  size = contactSidebarSize.NORMAL,
  withCompanyPeople = false,
  companyPeopleFilterEmails = [],
  companyPeopleFilterIDs = [],
  onUpdate,
  onRightComponentClick,
  isWebsite,
}: Props): JSX.Element {
  const workspaceId = useCurrentWorkspaceId();

  const [selectedData, setSelectedData] = useState({
    person,
    website,
    isLoading: false,
  });

  const websiteId = person?.website?.id ?? website?.id;

  const { data: companyPeople } = useQuery<PeopleShortResponse.AsObject[]>(
    ['contacts-sidebar-company-people-header', websiteId],
    () => getPeopleByCompanyIdApi(websiteId!, workspaceId),
    {
      select: (data) =>
        data
          .filter((item) =>
            companyPeopleFilterIDs.length ? companyPeopleFilterIDs.includes(item.id) : true
          )
          .filter((item) =>
            companyPeopleFilterEmails.length
              ? item.emailsList.some((email) => companyPeopleFilterEmails.includes(email.email))
              : true
          ),
      enabled: !!websiteId && withCompanyPeople,
      refetchOnWindowFocus: false,
      placeholderData: [],
    }
  );

  useEffect(() => {
    if (companyPeople && companyPeople.length > 0) {
      // @ts-ignore
      const emails = companyPeople.map((it) => it.emailsList).flat();
      const companyPerson = {
        ...companyPeople[0],
        emailsList: emails,
        companyId: websiteId,
        companyWebsite: website?.domain ?? person?.website?.domain ?? '',
      };
      setSelectedData((prevState) => ({ ...prevState, person: { ...person, ...companyPerson } }));
    }
  }, [companyPeople, person, websiteId, website?.domain]);

  useEffect(() => {
    setSelectedData({ person, website, isLoading: false });
  }, [person, website]);

  if (!isOpen || !(selectedData.person || selectedData.website)) {
    return null;
  }

  if (size === contactSidebarSize.SMALL) {
    return (
      <div className="contact-sidebar-crm--small">
        <ContactSidebarCRMHeader
          person={selectedData.person}
          website={selectedData.website}
          isWebsite={isWebsite}
          size={contactSidebarSize.NORMAL}
        />
        <Loader isLoading={selectedData.isLoading} withTopMargin>
          <ContactSidebarCRMContent
            person={selectedData.person}
            website={selectedData.website}
            onUpdate={(newPerson, newWebsite) =>
              setSelectedData((prevData) => ({
                ...prevData,
                person: newPerson ?? prevData.person,
                website: newWebsite ?? prevData.website,
              }))
            }
            onRightComponentClick={onRightComponentClick}
          />
        </Loader>
      </div>
    );
  }

  return (
    <RightInfoSidebar
      isOpen={isOpen}
      onClose={onClose}
      classNames={{
        container: 'contact-sidebar-crm__container',
        header: 'contact-sidebar-crm__header-container',
        closeIcon: 'contact-sidebar-crm__close-icon',
        body: 'contact-sidebar-crm__body',
      }}
      title={
        <ContactSidebarCRMHeader
          person={selectedData.person}
          website={selectedData.website}
          isWebsite={isWebsite}
        />
      }
    >
      <ContactSidebarCRMContent
        person={selectedData.person}
        website={selectedData.website}
        queryKey={queryKey}
        onUpdate={onUpdate}
        onRightComponentClick={onRightComponentClick}
      />
    </RightInfoSidebar>
  );
}

export default ContactSidebarCRM;
