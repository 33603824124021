import { addCompanyNoteApi, removeCompanyNoteApi, updateCompanyNoteApi } from '@api/website.api';
import React, { useRef, useState } from 'react';
import { useQueryClient } from 'react-query';

import { NoteType } from '@ts/common.types';
import { WorkspaceMemberShortType } from '@ts/workspace.types';

import { getDateShort } from '@utils/date';

import useWorkspaceMembers from '@hooks/useWorkspaceMembers';

import Avatar from '@uikit/Avatar/Avatar';
import { Button } from '@uikit/Button/Button';
import Textarea from '@uikit/Textarea/Textarea';
import { SVGIcon } from '@uikit/Icon/Icon';

import './OpportunityContactSidebarSectionNotes.scss';
import { useDispatch } from 'react-redux';
import { DispatchType } from 'src/store';
import OpportunityContactSidebarSection from '@components/CampaignSettings/OpportunityContactSidebar/_components/OpportunityContactSidebarSection/OpportunityContactSidebarSection';
import { OpportunityDetailsType, OpportunitySidebarProps } from '@ts/opportunity.types';
import { addNotification } from '@redux/actions/notifications.actions';

function OpportunityContactSidebarSectionNotes({ opportunity, index }: OpportunitySidebarProps) {
  const { website } = opportunity;
  const { notesList: notes } = website;
  const ref = useRef(null);

  const dispatch = useDispatch<DispatchType>();
  const queryClient = useQueryClient();
  const [isOpen, setOpen] = useState(false);

  const [isCreating, setIsCreating] = useState<boolean>(false);

  const { members, isFetching } = useWorkspaceMembers();

  const handleAddNote = (value: string) => {
    return addCompanyNoteApi(value, website.id).then((newNote) => {
      setIsCreating(false);
      queryClient.setQueryData(
        ['active-opportunity', opportunity.id],
        (cachedOpp: OpportunityDetailsType) => ({
          ...cachedOpp,
          website: {
            ...cachedOpp.website,
            notesList: [...(cachedOpp.website?.notesList || []), newNote],
          },
        })
      );
      dispatch(addNotification({ title: 'Note added', type: 'success' }));
    });
  };

  const handleUpdateNote = (newValue: string, noteId: number) => {
    updateCompanyNoteApi(noteId, newValue, website.id).then(() => {
      queryClient.setQueryData(
        ['active-opportunity', opportunity.id],
        (cachedOpp: OpportunityDetailsType) => ({
          ...cachedOpp,
          website: {
            ...cachedOpp.website,
            notesList: (cachedOpp.website?.notesList || []).map((note) => {
              if (note.id !== noteId) {
                return note;
              }

              return { ...note, content: newValue };
            }),
          },
        })
      );
    });
  };

  const handleRemoveNote = (noteId: number) => {
    removeCompanyNoteApi(noteId, website.id).then(() => {
      queryClient.setQueryData(
        ['active-opportunity', opportunity.id],
        (cachedOpp: OpportunityDetailsType) => ({
          ...cachedOpp,
          website: {
            ...cachedOpp.website,
            notesList: (cachedOpp.website?.notesList || [])
              .slice()
              .filter((item: NoteType) => item.id !== noteId),
          },
        })
      );
      dispatch(addNotification({ title: 'Note removed', type: 'success' }));
    });
  };

  return (
    <OpportunityContactSidebarSection
      ref={ref}
      index={index}
      id="notes"
      title="Notes"
      showArrow={notes?.length > 0}
      onOpen={() => setOpen(!isOpen)}
      isInitialOpen={notes?.length > 0}
      rightComponent={
        website == null || website.id <= 0 ? null : (
          <div
            className="opportunity-contacts-sidebar-crm__content__add-btn"
            onClick={() => {
              ref.current.setOpen(true);
              setIsCreating(true);
            }}
          >
            <SVGIcon icon="plusIcon" size={10} color="#221CB6" />
            Add
          </div>
        )
      }
    >
      <div className="opportunity-contacts-sidebar-section-notes__container">
        {isCreating && <NoteEditor onSave={handleAddNote} onClose={() => setIsCreating(false)} />}
        {notes?.length
          ? notes.map((note) => (
              <NotePreview
                key={note.id}
                note={note}
                members={members}
                onSave={handleUpdateNote}
                onRemove={handleRemoveNote}
              />
            ))
          : null}

        {!notes?.length && !isCreating ? (
          <div className="opportunity-contacts-sidebar-section-notes__container--empty">Empty</div>
        ) : null}
      </div>
    </OpportunityContactSidebarSection>
  );
}

export default OpportunityContactSidebarSectionNotes;

function NotePreview({
  note,
  members,
  onSave,
  onRemove,
}: {
  note: NoteType;
  members: WorkspaceMemberShortType[];
  onSave?: (newValue: string, noteId: number) => void;
  onRemove?: (noteId: number) => void;
}) {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const owner = members.find((member) => member.userId === note.ownerId);

  if (isEditing) {
    return (
      <NoteEditor
        onSave={(data) => {
          onSave(data, note.id);
          setIsEditing(false);
        }}
        onClose={() => setIsEditing(false)}
        initialValue={note.content}
      />
    );
  }

  return (
    <div className="opportunity-contacts-sidebar-section-notes__note">
      <div className="opportunity-contacts-sidebar-section-notes__note-header">
        <span onClick={() => setIsEditing(true)}>
          <SVGIcon icon="pencil" color="#868686" size={10} />
        </span>
        <span onClick={() => onRemove(note.id)}>
          <SVGIcon icon="trashSimple" color="#868686" size={10} />
        </span>
      </div>

      {note.content}
      <div className="opportunity-contacts-sidebar-section-notes__footer">
        <div className="opportunity-contacts-sidebar-section-notes__footer-owner">
          <Avatar
            url={owner?.logoUrl}
            size="xxs"
            className="opportunity-contacts-sidebar-section-notes__footer-owner-logo"
          />
          <span>{owner?.fullName || '-'}</span>
        </div>
        <div className="opportunity-contacts-sidebar-section-notes__footer-time">
          {getDateShort(new Date(note.createdAt), true, true)}
        </div>
      </div>
    </div>
  );
}

function NoteEditor({
  onSave,
  onClose,
  initialValue,
}: {
  onSave: (note: string) => void;
  onClose: () => void;
  initialValue?: string;
}) {
  const [newNote, setNewNote] = useState<string>(initialValue || '');

  return (
    <div className="opportunity-contacts-sidebar-section-notes__note">
      <Textarea
        placeholder="Enter note"
        name="newNote"
        // resizeDisabled
        isFullWidth
        className="opportunity-contacts-sidebar-section-notes__note-textarea"
        value={newNote}
        onChange={({ target: { value } }) => setNewNote(value)}
      />
      <div
        style={{ justifyContent: 'flex-end', marginTop: '5px' }}
        className="opportunity-contacts-sidebar-section-notes__footer"
      >
        <Button
          onClick={() => {
            setNewNote('');
            onClose();
          }}
          className="opportunity-contacts-sidebar-section-notes__footer-cancel-btn"
          type="bordered-grey"
        >
          Cancel
        </Button>
        <Button
          disabled={!newNote}
          className="opportunity-contacts-sidebar-section-notes__footer-btn"
          type="primary"
          onClick={() => onSave(newNote)}
        >
          Save
        </Button>
      </div>
    </div>
  );
}
