import { SVGIcon } from '@uikit/Icon/Icon';
import React, { useRef, useState } from 'react';

import './OpportunityContactSidebarSectionCampaigns.scss';
import { useQuery } from 'react-query';
import OpportunityContactSidebarSection from '@components/CampaignSettings/OpportunityContactSidebar/_components/OpportunityContactSidebarSection/OpportunityContactSidebarSection';
import {OpportunityDetailsType, OpportunitySidebarProps} from '@ts/opportunity.types';
import { getCampaignOpportunitiesByPersonIdInApi } from '@api/people.api';
import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';
import { PeopleOpportunityType } from '@ts/people.types';
import { OpportunityPersonContactType } from '@ts/contact.types';
import Loader from '@uikit/Loader/Loader';

const OpportunityContactSidebarSectionCampaigns = ({
  opportunity,
  index,
}: OpportunitySidebarProps) => {
  if (opportunity == null) {
    return <></>;
  }
  const ref = useRef();
  const [isOpen, setOpen] = useState(false);
  const contacts = opportunity.contactsList;
  const personIdList = contacts.map((it) => it.personId).sort();

  const workspaceId = useCurrentWorkspaceId();

  const { data: personCampaignsData, isLoading: isLoadingPersonCampaigns } = useQuery<
    PeopleOpportunityType[]
  >(
    ['opportunity-contacts-sidebar-campaigns', ...personIdList],
    () => getCampaignOpportunitiesByPersonIdInApi(personIdList, workspaceId),
    {
      enabled:
        !!workspaceId &&
        !!opportunity &&
        !!opportunity.website &&
        opportunity.website.id > 0 &&
        isOpen,
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 5 * (60 * 1000),
    }
  );

  return (
    <OpportunityContactSidebarSection
      ref={ref}
      index={index}
      id="campaigns"
      title="Campaigns"
      showArrow
      isInitialOpen={false}
      onOpen={() => setOpen(!isOpen)}
    >
      <Loader isLoading={isLoadingPersonCampaigns}>
        <div className="opportunity-contacts-sidebar-section-campaigns">
          {personCampaignsData &&
            personCampaignsData.map((personCampaign) => (
              <OpportunityContactSidebarSectionCampaignItem
                campaign={personCampaign}
                contacts={opportunity.contactsList}
              />
            ))}
        </div>
      </Loader>
    </OpportunityContactSidebarSection>
  );
};

const OpportunityContactSidebarSectionCampaignItem = ({
  campaign,
  contacts,
}: {
  campaign: PeopleOpportunityType;
  contacts: OpportunityPersonContactType[];
}) => {
  return (
    <div className="opportunity-contacts-sidebar-section-campaigns__campaign">
      <div className="opportunity-contacts-sidebar-section-campaigns__campaign-title">
        <span>{campaign.campaignTitle}</span>
        <SVGIcon icon="externalLink" color="#221CB6" size={13} />
      </div>
      <div className="opportunity-contacts-sidebar-section-campaigns__campaign-people">
        <SVGIcon icon="personRounded" color="#C4C6CD" />
        {campaign.email}
      </div>
      <div className="opportunity-contacts-sidebar-section-campaigns__campaign-status">
        <SVGIcon icon="flag" color="#C4C6CD" />
        {campaign.opportunityStatus}
      </div>
      <div className="opportunity-contacts-sidebar-section-campaigns__campaign-date">
        <SVGIcon icon="clockThree" color="#C4C6CD" />
        {new Date(campaign.lastActivity).toLocaleDateString()}
      </div>
      <div className="opportunity-contacts-sidebar-section-campaigns__campaign-next-action">
        <SVGIcon icon="cardio" color="#C4C6CD" />
        {new Date(campaign.lastActivity).toLocaleDateString()}
      </div>
    </div>
  );
};

export default OpportunityContactSidebarSectionCampaigns;
