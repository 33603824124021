import React, { useRef, useState } from 'react';
import { useQuery } from 'react-query';
import ReactTooltip from 'react-tooltip';

import parse from 'emailjs-mime-parser';

import { ThreadMessage } from 'respona_api/generated/mailbox_pb';

import { getDateShort } from '@utils/date';

import { getLatestEmailByThreadId, getPreviewEmailsByPersonIdInApi } from '@api/mailbox.api';

import useCurrentWorkspaceId from '@hooks/useCurrentWorkspaceId';

import { SVGIcon } from '@uikit/Icon/Icon';
import Loader from '@uikit/Loader/Loader';
import { NamedAvatar } from '@uikit/Avatar/Avatar';
import MimeMessage from '@uikit/MimeMessage/MimeMessage';

import './OpportunityContactSidebarSectionHistory.scss';
import { OpportunitySidebarProps } from '@ts/opportunity.types';
import OpportunityContactSidebarSection from '@components/CampaignSettings/OpportunityContactSidebar/_components/OpportunityContactSidebarSection/OpportunityContactSidebarSection';
import { ThreadPreviewResponseType } from '@ts/mailboxInbox.types';

function OpportunityContactSidebarSectionHistory({ opportunity, index }: OpportunitySidebarProps) {
  const { website, contactsList } = opportunity;
  if (website == null || website.id <= 0 || contactsList == null || contactsList.length <= 0) {
    return <></>;
  }
  const personIds = contactsList.map((it) => it.personId).sort();
  const ref = useRef(null);

  const workspaceId = useCurrentWorkspaceId();

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isOpen, setOpen] = useState(false);

  const { data: dataInboxPreview, isLoading: isLoadingInboxPreview } = useQuery<
    ThreadPreviewResponseType[]
  >(
    ['opportunity-contacts-sidebar-emails', ...personIds],
    () => getPreviewEmailsByPersonIdInApi(workspaceId, personIds),
    {
      enabled: !!workspaceId && !!personIds && personIds.length && isOpen,
      refetchOnWindowFocus: false,
      staleTime: 5 * (60 * 1000),
    }
  );

  return (
    <OpportunityContactSidebarSection
      ref={ref}
      index={index}
      id="threads"
      title="Conversation History"
      showArrow
      isInitialOpen={false}
      onOpen={() => setOpen(!isOpen)}
    >
      <div className="opportunity-contacts-sidebar-section-emails">
        <Loader isLoading={isLoadingInboxPreview}>
          {dataInboxPreview?.length > 0 ? (
            dataInboxPreview?.slice(0, isExpanded ? undefined : 5)?.map((email) => (
              <React.Fragment key={email.uid}>
                <div
                  onClickCapture={() => ReactTooltip.hide()}
                  className="opportunity-contacts-sidebar-section-emails__email"
                  data-for={`opportunity-contacts-sidebar-section-emails-${email.uid}`}
                  data-tip=""
                >
                  <div className="opportunity-contacts-sidebar-section-emails__email-date">
                    {email.lastActivityTime > 0
                      ? getDateShort(new Date(email.lastActivityTime))
                      : null}
                  </div>

                  <div className="opportunity-contacts-sidebar-section-emails__email-title">
                    <SVGIcon icon="email" color="#221CB6" size={20} />
                    <span>{email.fromEmail}</span>
                  </div>
                  <div className="opportunity-contacts-sidebar-section-emails__email-text">
                    {email.subject}
                  </div>
                </div>

                <ReactTooltip
                  id={`opportunity-contacts-sidebar-section-emails-${email.uid}`}
                  className="react-tooltip opportunity-contacts-sidebar-section-emails__email-tooltip"
                  place="left"
                  effect="solid"
                  event="click"
                  globalEventOff="click"
                  arrowColor="transparent"
                  clickable
                >
                  <ContactsSidebarCRMSectionEmailsPreview email={email} />
                </ReactTooltip>
              </React.Fragment>
            ))
          ) : (
            <div className="opportunity-contacts-sidebar-section-emails--empty">Empty</div>
          )}

          {!isExpanded && dataInboxPreview?.length > 5 && (
            <div
              className="opportunity-contacts-sidebar-crm__show-more-btn"
              onClick={() => setIsExpanded(true)}
            >
              Show More
              <SVGIcon icon="chevron" size={8} />
            </div>
          )}
        </Loader>
      </div>
    </OpportunityContactSidebarSection>
  );
}

export default OpportunityContactSidebarSectionHistory;

function ContactsSidebarCRMSectionEmailsPreview({ email }: { email: ThreadPreviewResponseType }) {
  const threadUid = email?.uid;
  const currentWorkspaceId = useCurrentWorkspaceId();

  const { data: preview, isLoading } = useQuery<ThreadMessage.AsObject>(
    ['contacts-sidebar-emails-preview', threadUid],
    () => getLatestEmailByThreadId(threadUid, currentWorkspaceId),
    {
      enabled: !!threadUid,
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 5 * (60 * 1000),
    }
  );

  return (
    <div className="opportunity-contacts-sidebar-section-emails__email-preview">
      <div className="opportunity-contacts-sidebar-section-emails__email-preview-header">
        <div
          className="opportunity-contacts-sidebar-section-emails__email-preview-header-link"
          onClick={() => window.open(`/inbox/inbox?threadId=${threadUid}`, '_blank')}
        >
          <SVGIcon icon="externalLink" color="#BDBDBD" size={12} />
        </div>

        <NamedAvatar
          size="m"
          userName={email.fromName}
          className="opportunity-contacts-sidebar-section-emails__email-preview-header-avatar"
        />

        <div className="opportunity-contacts-sidebar-section-emails__email-preview-header-text">
          <span className="opportunity-contacts-sidebar-section-emails__email-preview-header-text-email">
            {email.fromEmail}
          </span>
          <span>{email.fromEmail}</span>
          <span>Subject: {email.subject}</span>
        </div>
      </div>

      <Loader withTopMargin isLoading={isLoading || !preview}>
        <div className="opportunity-contacts-sidebar-section-emails__email-preview-content">
          {preview?.content ? <MimeMessage mimeMessage={parse(preview.content)} /> : null}
        </div>
      </Loader>
    </div>
  );
}
