import { useMemo, useCallback, useRef, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { getCustomVariableValuesApi } from '@api/customVariables.api';
import { CustomVariableValueType } from '@ts/customVariales.types';
import { QueryCollectionHookType } from '@ts/common.types';

const compareCustomVariables = (a: CustomVariableValueType, b: CustomVariableValueType): number => {
  return a.relatedTo - b.relatedTo;
};

const useCustomVariablesValues = (
  variableNames: string[],
  relatedIds: number[],
  enabled: boolean = true
): QueryCollectionHookType<CustomVariableValueType> => {
  const queryClient = useQueryClient();
  const compare = useMemo(() => compareCustomVariables, []);
  const queryKey = useRef(['custom-variable-values', ...relatedIds, ...variableNames]);

  useEffect(() => {
    queryKey.current = ['custom-variable-values', ...relatedIds, ...variableNames];
  }, [relatedIds, variableNames]);

  const {
    data: customVariablesValues,
    isLoading,
    isRefetching,
    isFetching,
    isSuccess,
    refetch,
  } = useQuery<CustomVariableValueType[], Error>(
    queryKey.current,
    () => getCustomVariableValuesApi(variableNames, relatedIds),
    {
      enabled: enabled && variableNames.length > 0 && relatedIds.length > 0,
      refetchOnWindowFocus: false,
      select: (data) => data.sort(compare),
    }
  );

  const removeItem = useCallback(
    (id: number) => {
      queryClient.setQueryData(
        queryKey.current,
        (oldData: CustomVariableValueType[] | undefined) =>
          oldData?.filter((item) => item.variableId !== id) ?? []
      );
    },
    [queryClient, queryKey.current]
  );

  const updateInCache = useCallback(
    (item: CustomVariableValueType) => {
      queryClient.setQueryData(
        queryKey.current,
        (oldData: CustomVariableValueType[] | undefined) => {
          if (!oldData) return [item];
          const existingIndex = oldData.findIndex(
            (oldItem) => oldItem.variableId === item.variableId
          );
          if (existingIndex === -1) return [...oldData, item];
          return oldData.map((oldItem, ind) => (ind === existingIndex ? item : oldItem));
        }
      );
    },
    [queryClient, queryKey.current]
  );

  const evictByKey = useCallback(
    (id: number) => {
      queryClient.setQueryData(
        queryKey.current,
        (oldData: CustomVariableValueType[] | undefined) =>
          oldData?.filter((item) => item.relatedTo !== id) ?? []
      );
    },
    [queryClient, queryKey]
  );

  return {
    items: customVariablesValues,
    isLoading: isLoading || isFetching || isRefetching,
    isEmpty: isSuccess && (customVariablesValues?.length ?? 0) === 0,
    refetch,
    removeItem,
    addItem: (item) => updateInCache(item),
    updateItem: (id, item) => updateInCache(item),
    evictByKey,
  };
};

export default useCustomVariablesValues;
